import { FC } from 'react';

import classes from './OurTeam.module.scss';

interface Props {
  data: '';
  title: string;
}

export const OurTeam: FC<Props> = ({
  data,
  title
}) => {
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {title}
      </div>
      <div dangerouslySetInnerHTML={{__html: data}} />
    </div>
  );
}