import React, { FC, useEffect } from 'react';
import { useAuthStore } from "../contexts/AuthContext";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

export interface ProtectedRouteProps {
    children?: any;
}

export const ProtectRoute: FC<ProtectedRouteProps> = observer(() => {
    const authStore = useAuthStore();
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (!toJS(authStore).isAuth) {
            navigate(`${process.env.REACT_APP_LOGIN_URL}`, { replace: true, state: { from: location } })
        }
    }, [authStore.isAuth])

    if (!toJS(authStore).isAuth) {
        return <Navigate to={`${process.env.REACT_APP_LOGIN_URL}`} replace state={{ from: location }} />
    }

    return <Outlet />
})
