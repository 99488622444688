import * as React from "react";
import { SVGProps } from "react";

export const RubIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="36"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#FFEAEA"
    />
    <path
      d="M16.8782 17.728H11.2479V16.3324H16.8569C17.3399 16.3324 17.7394 16.2525 18.0554 16.0927C18.3715 15.9293 18.6059 15.7056 18.7586 15.4215C18.9113 15.1339 18.9876 14.8036 18.9876 14.4308C18.9876 14.0614 18.9113 13.7312 18.7586 13.44C18.6059 13.1488 18.3733 12.9197 18.0608 12.7528C17.7483 12.5824 17.3541 12.4972 16.8782 12.4972H14.5558V22H12.9152V11.0909H16.8782C17.7057 11.0909 18.3964 11.2401 18.9503 11.5384C19.5079 11.8366 19.9251 12.2379 20.2021 12.7422C20.4826 13.2464 20.6229 13.8075 20.6229 14.4254C20.6229 15.0504 20.4809 15.6133 20.1968 16.114C19.9162 16.6112 19.4972 17.0053 18.9397 17.2965C18.3857 17.5842 17.6986 17.728 16.8782 17.728ZM16.9581 18.6442V20.0451H11.2479V18.6442H16.9581Z"
      fill="#9C8B8B"
    />
  </svg>
);
