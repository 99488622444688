import { FC, useState, useRef, useEffect } from "react";
import clsx from "clsx";

import { Button } from "components/Button";
import { SmallPurpleArrowDownIcon } from "components/Icons/SmallPurpleArrowDownIcon";

import classes from "./Cases.module.scss";
import { useWindowWidth } from "hooks/useWindowWidth";
import { Modal } from "components/Modal";
import { amountType } from "constants/amountType";
import { amountUnit } from "constants/amountUnit";
import { useTranslation } from "react-i18next";
import { getLangName } from "../../../tools/utils";
import i18n from "i18n/i18n";
import { casesStore } from "stores/casesStore";
import { observer } from "mobx-react-lite";
import UiIcon from "../../../components/Icon";
import { formatBalanceForLocale } from "containers/Finances/formatBalanceForLocale";

interface Props {
  data: any;
}

export const Cases: FC<Props> = observer(({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const { isSmallTablet } = useWindowWidth();
  const { t, i18n } = useTranslation();
  const open = () => {
    if (isSmallTablet) {
      casesStore.openModal();
    } else {
      setShowMore(true);
    }
  };
  
  return (
    <div>
      <div className={classes.title}>{t("Cases")}</div>
      <div className={classes.cases}>
        {data.map((item: any) => (
          <div key={item.id} className={classes.case}>
            <div className={classes["image-wrapper"]}>
              <img className={classes.image} src={item.image} alt="" />
            </div>
            <div className={classes.info}>
              <div className={classes.head}>
                <div className={classes.type}>
                  {/* {getLangName(item.business_type, 'name', i18n)}
                  <span>•</span> */}
                  {getLangName(item, "achievement", i18n)}
                </div>
                <div className={classes.tags}>
                  <div className={classes.tag}>
                    {getLangName(item.product, "name", i18n)}
                  </div>
                  {item.platform.map((tag: any) => {
                    if (tag.image) {
                      return (
                        <div className={classes.tag_img}>
                          <img
                            className={classes.platform}
                            src={tag.image}
                            alt=""
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className={classes.body}>
                <div
                  className={classes.name}
                  dangerouslySetInnerHTML={{
                    __html: getLangName(item, "description", i18n),
                  }}
                />
                {!showMore && !isSmallTablet ? (
                  <Button
                    onClick={() => setShowMore(true)}
                    className={classes.button}
                    theme="text"
                  >
                    {t("Show more")}
                    <div
                      className={clsx(classes["button-icon"], classes.rotate)}
                    >
                      <SmallPurpleArrowDownIcon />
                    </div>
                  </Button>
                ) : (
                  !isSmallTablet && (
                    <Button
                      onClick={() => setShowMore(false)}
                      className={classes.button}
                      theme="text"
                    >
                      {t("Show less")}
                      <div className={clsx(classes["button-icon"])}>
                        <SmallPurpleArrowDownIcon />
                      </div>
                    </Button>
                  )
                )}
                {isSmallTablet && (
                  <Button
                    onClick={open}
                    className={classes.button}
                    theme="text"
                  >
                    {t("Read more")}
                    <div
                      className={clsx(classes["button-icon"], classes.rotate)}
                    >
                      <SmallPurpleArrowDownIcon />
                    </div>
                  </Button>
                )}
              </div>
              {showMore && !isSmallTablet && (
                <div className={classes.kpises}>
                  {item.case_kpises.map((kpi: any) => (
                    <div className={classes.kpi}>
                      <div className={classes.description}>
                        {kpi.description}
                      </div>
                      <div className={classes.amount}>
                        {amountType[kpi.amount_type as keyof typeof amountType]}
                        {formatBalanceForLocale(kpi.amount, kpi.amount_unit)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Modal
                title={""}
                isOpen={casesStore.isOpened}
                className={classes.del_modal}
                onClose={() => casesStore.closeModal()}
              >
                <div className={classes.modalContainer}>
                  <div className={classes.modalInner}>
                    <span className={classes.modalTitle}>
                      {t("New credit requests approved in TrueBalance")}
                    </span>
                  </div>
                  <div className={classes.kpises}>
                    {item.case_kpises.map((kpi: any) => (
                      <div className={classes.kpi}>
                        <div className={classes.description}>
                          {kpi.description}
                        </div>
                        <div className={classes.amount}>
                        {amountType[kpi.amount_type as keyof typeof amountType]}
                          {formatBalanceForLocale(kpi.amount, kpi.amount_unit)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    className={classes.modalClose}
                    onClick={() => casesStore.closeModal()}
                  >
                    <UiIcon
                      name="CrossIcon"
                      additionalClassName={classes["close-icon"]}
                    />
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
