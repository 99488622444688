import {useState, useEffect} from "react";
import { useWindowWidth } from "hooks/useWindowWidth"


export const useBreakPoints = () => {
    const isTablet = useWindowWidth().isMediaTablet;
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (isTablet) setIsHovered(true);
      }, [isTablet]);
      
      return {
        isTablet, isHovered, setIsHovered
      }
}
