import {makeAutoObservable, runInAction} from "mobx";

class CasesStore {
  zIndex = 2
  isOpened = false

  constructor() {
    makeAutoObservable(this)
  }

  openModal(){
    this.isOpened = true
  }

  closeModal(){
    this.isOpened = false
  }

}

export const casesStore = new CasesStore()
