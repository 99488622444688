import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils"

const PercentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="-4 0 32 32"
    fill={props.fill}
    {...props}
  >
    <title>{"percent"}</title>
    <path d="M2.25 27.188 18.938 1.594h2.406L4.594 27.188H2.25zm3.5-25.125c2.969 0 5.406 2.438 5.406 5.406 0 3-2.438 5.438-5.406 5.438-3 0-5.438-2.438-5.438-5.438C.312 4.5 2.75 2.063 5.75 2.063zm0 8.906c1.906 0 3.469-1.594 3.469-3.5S7.656 4.031 5.75 4.031 2.281 5.562 2.281 7.469s1.563 3.5 3.469 3.5zm12.563 4.437c3 0 5.438 2.438 5.438 5.438 0 2.969-2.438 5.438-5.438 5.438-2.969 0-5.406-2.469-5.406-5.438 0-3 2.438-5.438 5.406-5.438zm0 8.907c1.906 0 3.469-1.563 3.469-3.469s-1.563-3.469-3.469-3.469-3.469 1.563-3.469 3.469 1.563 3.469 3.469 3.469z" fill="url(#paint0_linear_4449_92984)" />
    <defs>
      {RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_4449_92984" x1="-3.20984" y1="-7.13385" x2="14.5006" y2="9.05271" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFB3D2" />
          <stop offset="1" stop-color="#FF4848" />
        </linearGradient>
      }
      {!RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_4449_92984" x1="-0.193307" y1="4.49584" x2="17.501" y2="19.9137" gradientUnits="userSpaceOnUse">
          <stop stop-color="#4BA5FF" />
          <stop offset="1" stop-color="#A54BFF" />
        </linearGradient>
      }
    </defs>
  </svg>
)
export default PercentIcon