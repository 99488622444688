import { FC, useEffect, useState, useCallback } from "react";
import * as yup from "yup";

import classes from "./FormRequest.module.scss";
import clsx from "clsx";

import { Request } from "tools/request";
import { Type } from "components/Type/index";
import { Textarea } from "components/Input/Textarea";
import { Grid, GridItem } from "components/Grid/index";
import { Button } from "components/Button";
import { CheckCircleGradientItem } from "components/Icons/CheckCircleGradientItem";
import { defaultLanguage } from "i18n/i18n";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RUSSIAN_LOCALE } from "utils";

interface Props {
  onClose: any;
}

export const FormRequest: FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const validationSchema = yup.object().shape({
    solution: yup.string().required(t("This field is required")),
    source: yup.string().required(t("This field is required")),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });

  const [disabled, setDisabled] = useState(true);
  const [serviceValue, setServiceValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    project_description: "",
    service_name: "",
  });

  useEffect(() => {
    if (
      serviceValue !== "" &&
      sourceValue !== "" &&
      !!serviceValue.trim() &&
      !!sourceValue.trim()
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [serviceValue, sourceValue]);

  const onCloseSuccess = () => {
    setSuccess(false);
    onClose();
  };

  const sendRequest = useCallback(async () => {
    let formData = new FormData();
    let sourcePage = window.location?.href;
    formData.append("service_name", serviceValue);
    formData.append("project_description", sourceValue);
    formData.append("source_page", sourcePage);

    if (disabled) {
      return;
    }

    setDisabled(true);

    try {
      await Request({
        type: "POST",
        url: `${process.env.REACT_APP_API}/api/v1/services/service_request/`,
        data: formData,
      });
      setSuccess(true);
      setDisabled(false);
      setServiceValue("");
      setSourceValue("");
    } catch (error: any) {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          setErrorMessage(data);
        }
      }
      setDisabled(false);
    }
  }, [serviceValue, sourceValue, disabled]);

  const submit = useCallback(() => {
    void sendRequest();
    reset();
  }, [reset, sendRequest]);

  return (
    <form
      action=""
      className={clsx(classes.form, success && classes.gradient)}
      onSubmit={handleSubmit(submit)}
    >
      {!success && (
        <div className={classes.inner}>
          <div className={classes.title}>
            <Type tag="span" size="xs-bold">
              {t("Request for a new solution")}
            </Type>
          </div>
          <Grid direction="row" className={classes.box}>
            <GridItem col={{ default: 12 }} className={classes.item}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Textarea
                    error={errorMessage?.service_name}
                    value={field.value}
                    label={t("Solution")}
                    maxLength={100}
                    placeholder={t("Tell us more about your needs")}
                    onChange={(e: any) => {
                      setServiceValue(e);
                      field.onChange(e);
                    }}
                  />
                )}
                name="solution"
              />
              {errorMessage?.service_name ? (
                <p className={classes.error}>{errorMessage.service_name}</p>
              ) : null}
            </GridItem>
            <GridItem col={{ default: 12 }} className={classes.item}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Textarea
                    maxLength={100}
                    error={errorMessage?.project_description}
                    value={field.value}
                    label={t("Source")}
                    placeholder={t("What source do you need this solution for")}
                    onChange={(e: any) => {
                      setSourceValue(e);
                      field.onChange(e);
                    }}
                  />
                )}
                name="source"
              />
              {errorMessage?.project_description ? (
                <p className={classes.error}>
                  {errorMessage.project_description}
                </p>
              ) : null}
            </GridItem>
            <GridItem col={{ default: 12 }} className={classes.item}>
              <p className={classes.policy}>
                {t(
                  "By applying this form You further agree on the terms of our",
                )}{" "}
                <a
                  className={classes["policy__link"]}
                  href={
                    RUSSIAN_LOCALE
                      ? process.env.REACT_APP_PRIVACY_POLICY_URL
                      : `https://kit.global/${
                          i18n.language !== defaultLanguage
                            ? i18n.language + "/"
                            : ""
                        }privacy-policy/`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Privacy Policy")}
                </a>
              </p>
            </GridItem>
          </Grid>
          <Button
            theme={"primary"}
            size="middle"
            disabled={!isValid}
            className={classes.btn}
            type="submit"
          >
            {t("Send request_productList")}
          </Button>
        </div>
      )}
      {success && (
        <div className={classes.inner}>
          <div className={classes.icon}>
            <CheckCircleGradientItem />
          </div>
          <div className={classes.title}>
            <span className={classes.successTitle}>{t("Request sent")}</span>
          </div>
          <div className={classes.text}>
            <Type size="simple" tag="p" className={classes.textItem}>
              {t(
                "Thank you, we’ll go back to you with more information within 2 working days",
              )}
            </Type>
          </div>
          <div className={classes.btnSuccess}>
            <Button size="middle" theme="primary" onClick={onCloseSuccess}>
              {t("Accessibly")}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};
