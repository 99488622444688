import classes from "./RegistrationFormRu.module.scss";
import clsx from "clsx";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { MultiselectComponent } from "components/MultiselectComponent";
import { Controller } from "react-hook-form";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Link } from "components/Link";
import { defaultLanguage } from "i18n/i18n";
import { FC } from "react";
import { useRegistrationFormRu } from "./useRegistrationFormRu";

interface Props {
  kidUserInfo: { [key: string]: any };
}

export const RegistrationFormRu: FC<Props> = ({ kidUserInfo }) => {
  const {
    formRef,
    handleSubmit,
    submit,
    t,
    setcompanyNameSelect,
    selectedBusinessType,
    isStepOneValid,
    businessTypes,
    setSelectedBusinessType,
    i18n,
    setSelectedCompanySize,
    errors,
    control,
    setcompanyLink,
    companySizes,
    selectedCompanySize,
  } = useRegistrationFormRu(kidUserInfo);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(submit)}
      className={classes.form}
    >
      <div className={classes.headerWrapper}>
        <h3 className={classes.headerTitle}>{t("You are welcome!")}</h3>
        <p className={classes.headerDescription}>
          {t("Tell us about your business to see personalized solutions")}
        </p>
      </div>

      <div className={classes["top-fields"]}>
        <div
          className={clsx(classes["input-field"], classes["input-field--full"])}
        >
          <Controller
            render={({ field }) => {
              return (
                <Input
                  type={"text"}
                  placeholder={t("Enter company name")}
                  value={field.value}
                  onChange={e => {
                    field.onChange(e);
                    setcompanyNameSelect(e);
                  }}
                  error={!!errors?.companyName}
                />
              );
            }}
            control={control}
            name="companyName"
          />
          {errors?.companyName && (
            <TooltipContainer
              text={errors?.companyName?.message}
              customClasses={"kit-ui-block"}
              position={"top"}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
        <div
          className={clsx(classes["input-field"], classes["input-field--full"])}
        >
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                type={"text"}
                placeholder={t("Website, LinkedIn, Facebook, etc.")}
                className={classes.email}
                value={field.value}
                error={!!errors?.url}
                onChange={e => {
                  field.onChange(e);
                  setcompanyLink(e);
                }}
              />
            )}
            name="url"
          />
          {errors?.url && (
            <TooltipContainer
              text={errors.url?.message}
              customClasses={"kit-ui-block"}
              position={"top"}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
        <div
          className={clsx(classes["input-field"], classes["input-field--full"])}
        >
          <Controller
            name="size"
            control={control}
            render={() => (
              <MultiselectComponent
                data={companySizes}
                defaultSelected={selectedCompanySize?.id || null}
                placeholder={t("Company size")}
                setMultiselect={setSelectedCompanySize}
                search={false}
                isCompanySize
              />
            )}
          />
        </div>
        <div
          className={clsx(classes["input-field"], classes["input-field--full"])}
        >
          <Controller
            name="industry"
            control={control}
            render={() => (
              <MultiselectComponent
                data={businessTypes}
                defaultSelected={selectedBusinessType?.id || null}
                placeholder={t("Industry of the company")}
                setMultiselect={setSelectedBusinessType}
                search={false}
              />
            )}
          />
        </div>
      </div>

      <div className={classes["checkbox-block"]}>
        <Controller
          render={({ field }) => (
            <>
              <Checkbox onChange={field.onChange} checked={field.value} />
              <div className={classes.text}>
                <span>{t("I agree to KIT Global")} </span>
                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_MAIN_SITE_URL}/${
                    i18n.language !== defaultLanguage ? i18n.language + "/" : ""
                  }terms`}
                  target={"_blank"}
                >
                  {t("terms of Service (with)")}
                </Link>
                <span>{t("and")}</span>
                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`}
                  target={"_blank"}
                >
                  {t("privacy Policy (with)")}
                </Link>
              </div>
            </>
          )}
          name="iAgree"
          control={control}
        />
      </div>

      <div className={classes.submit}>
        <Button
          type="submit"
          className={clsx(classes.button, classes["submit-button"])}
          theme="primary"
          disabled={!isStepOneValid}
        >
          {t("Create account")}
        </Button>
      </div>
    </form>
  );
};
