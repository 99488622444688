import React from "react";
import classes from "./ErrorPage.module.scss";
import plug from 'img/System/plug.png'
import { useTranslation } from "react-i18next";


export const ErrorPageContainer = () => {

  const {t} = useTranslation();

  return (
    <div className={classes.wrap}>
      <div className={classes.inner}>
        <span className={classes.title}>{t("Error 500")}</span>
        <p className={classes.subtitle}>
          {t("Sorry, there are some technical issues. Please refresh the page or try again later")}
        </p>
      </div>
      <div className={classes.img}>
        <img src={plug} alt="plug" />
      </div>
    </div>
  );
};
