export const pushToDataLayer = (category: string, action: string, event: string) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		eCategory: category,
		eAction: action,
		event: event
	});
};

export const getGaClientId = () => {
	let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
	const raw = (match) ? decodeURIComponent(match[1]) : null;
	if (raw) {
		match = raw.match(/(\d+\.\d+)$/);
	}
	const gacid = (match) ? match[1] : null;
	if (gacid) {
		return gacid;
	}
}

export const gaClientId = getGaClientId()