import { FC, useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import { Button } from 'components/Button';
import { CardIcon } from 'components/Icons/CardIcon';
import { FilledHeartIcon } from 'components/Icons/FilledHeartIcon';
import { HeartIcon } from 'components/Icons/HeartIcon';
import { PurpleTimeIcon } from 'components/Icons/PurpleTimeIcon';
import PriceText from 'components/PriceText';
import { HotDealsCounter } from 'containers/CardsContainer/HotDeals/HotDealsCounter';
import {useFavorites} from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHotDeals } from 'hooks/useHotDeals';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import classes from './ServiceInfo.module.scss';
import { useUserStore } from 'contexts/UserContext';
import AddToCartButton from '../../../components/Button/AddToCartButton';
import FormatPrice from '../../../components/FormatPriceAndCurrency';
import { queryParamsHandler } from '../../CardsContainer/utils';
import { marketPlaceStore } from '../../../stores/marketPlaceStore';
import i18n from 'i18n/i18n';

interface Props {
  data: any;
  hotDeals: any;
  deals: {
    dateDealDate: number;
    monthEndDate: string;
    yearEndDate: number;
    active: boolean;
  };
}

export const ServiceInfo: FC<Props> = observer(({ data, hotDeals }) => {
  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [isFavorite, setIsFavorite] = useState(data?.is_favorite);

  const { t } = useTranslation();
  const { getHotDealText } = useHotDeals(t);
  const [hotDealDiscountText, setHotDealDiscountText] = useState('');

  useEffect(() => {
    const params = queryParamsHandler({ isHot: true });
    marketPlaceStore.loadHotDeals(params);
  }, []);

  const { width } = useWindowWidth();
  const userStore = useUserStore();
  const navigate = useNavigate();

  const changeFavorites = useFavorites(
    data,
    isFavorite,
    setIsFavorite,
    navigate
  );

  useEffect(() => {
    setIsFavorite(data?.is_favorite);
  }, [data]);

  const deals = data?.discounts?.find((item: any) => item.type === 'HOT_DEAL');

  useEffect(() => {
    setHotDealDiscountText(getHotDealText(deals, data));
  }, [data, deals]);

  const checkDiscount =
    data?.has_active_discounts &&
    Number(data.cost) !== Number(data.cost_with_discounts)
      ? Number(data.cost_with_discounts)
      : Number(data.cost);

  const checkCurrency = () => {
    return (
      userStore.user.currency.char_code === 'IDR' ||
      userStore.user.currency.char_code === 'INR'
    );
  };

  const formattedPrice = (price?: number | string, withMinus?: boolean) => (
    <FormatPrice
      currency={userStore.user.currency.symbol}
      price={price}
      withMinus={withMinus}
    />
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.tags}>
        {data?.is_new && (
          <div className={clsx(classes.tag, classes.new)}>{t('NEW')}</div>
        )}
        {data?.is_free && (
          <div className={clsx(classes.tag, classes.free)}>{t('FREE')}</div>
        )}
        <div className={classes.tag}>{data?.product?.name}</div>
      </div>
      <div className={classes.title}>
        {data[`name_${i18n.language}`] ?? data?.name}
      </div>
      <div className={classes['payment-info']}>
        <div className={classes.block}>
          <div className={classes.icon}>
            <CardIcon />
          </div>
          <div className={classes.info}>
            <div className={classes['info-title']}>{t('Payment')}</div>
            <div className={classes['info-text']}>
              {data?.payment_type &&
                data?.payment_type.map((item: any, index: number) => {
                  return (
                    <span key={index} className={classes.infoName}>
                      {item[`name_${i18n.language}`] ?? item?.name}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={clsx(classes.icon, classes.timer)}>
            <PurpleTimeIcon width={20} height={20} />
          </div>
          <div className={classes.info}>
            <div className={classes['info-title']}>{t('Duration')}</div>
            <div className={classes['info-text']}>
              {data[`period_comment_${i18n.language}`] ?? data?.period_comment}
            </div>
          </div>
        </div>
      </div>
      {data?.selling_description && data.selling_description !== '' && (
        <div className={classes.description}>
          <div className={classes['description-title']}>{t('Description')}</div>
          <div className={classes['description-text']}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data[`selling_description_${i18n.language}`] ??
                  data?.selling_description,
              }}
            />
          </div>
        </div>
      )}
      {data?.available_for && data.available_for !== '' && (
        <div className={classes.description}>
          <div className={classes['description-title']}>{t('Works for')}</div>
          <div className={classes['description-text']}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data[`available_for_${i18n.language}`] ?? data?.available_for,
              }}
            />
          </div>
        </div>
      )}

      {width < 961 && (
        <div className={classes['mobile-price-block']}>
          {hotDeals?.activeDeals &&
            !hotDeals?.featureDeals &&
            deals?.is_active && (
              <div className={classes.deals}>
                <div className={clsx(classes.deal)}>
                  {deals.cost_change_type === 'MONEY' &&
                    formattedPrice(undefined, true)}
                  {hotDealDiscountText}
                </div>
                <div className={classes.timer}>
                  <HotDealsCounter inner small detail />{' '}
                </div>
              </div>
            )}
          <div className={classes.price}>
            <div className={classes.text}>
              <PriceText data={data} t={t} asteriskSymbol />
            </div>
            {data?.is_free ? (
              <div className={classes.cost}>{t('Free')}</div>
            ) : (
              <div
                className={clsx(
                  classes.cost,
                  checkCurrency() && classes['cost--small']
                )}
              >
                {formattedPrice(checkDiscount)}
                {data?.has_active_discounts &&
                  Number(data.cost) !== Number(data.cost_with_discounts) && (
                    <span
                      className={clsx(
                        classes.discount,
                        checkCurrency() && classes['discount--small']
                      )}
                    >
                      {formattedPrice(data.cost)}
                    </span>
                  )}
              </div>
            )}
          </div>
          <div className={classes.buttons}>
            <AddToCartButton data={data} />
            <div className={classes.favored}>
              <Button
                className={classes.favorite}
                theme='icon-default'
                onClick={changeFavorites}
              >
                {isFavorite ? (
                  <FilledHeartIcon />
                ) : (
                  <HeartIcon fill={'#8362F3'} />
                )}
              </Button>
            </div>
          </div>
          {!data?.is_free && (
            <div className={classes.priceDesciption}>
              *{' '}
              {t(
                "This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our "
              )}{' '}
              <NavLink
                to={'/pricing-disclaimer/'}
                state={{ name: data.name }}
                className={classes.disclaimer}
              >
                {t('Pricing Disclaimer')}
              </NavLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
