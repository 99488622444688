export const getLinkForPrivacy = (language: string) => {
  console.log("language", language);
  if (language === "en") {
    return "https://kit.global/privacy-policy/";
  } else if (language === "es") {
    return "https://kit.global/es/privacy-policy/";
  } else if (language === "ru") {
    return "https://kokocgroup.ru/privacy/";
  }
  return "";
};
