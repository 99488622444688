import { FC, useEffect, useState } from "react";

import clsx from "clsx";

import classes from "./Price.module.scss";
import { Button } from "components/Button";
import {useFavorites} from "hooks";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { FilledHeartIcon } from "components/Icons/FilledHeartIcon";
import { HeartIcon } from "components/Icons/HeartIcon";
import { toJS } from "mobx";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { useCartStore } from "contexts/CartContext";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useUserStore } from "contexts/UserContext";
import AddToCartButton from "../../../components/Button/AddToCartButton";
import FormatPrice from "../../../components/FormatPriceAndCurrency";
import PriceText from "../../../components/PriceText";

interface Props {
  data: any;
  className?: string;
  deals: {
    dateDealDate: number,
    monthEndDate: string,
    yearEndDate: number,
    active: boolean,
  };
  activeDeals: boolean,
  featureDeals: boolean,
  nameSource?: string,
}
interface Source {
  id: number;
  name: string | null;
  name_en?: string | null;
  name_es?: string | null;
  name_es_mx?: string | null;
  name_hi?: string | null;
  name_ind?: string | null;
  name_ru?: string | null;
}

export const Price: FC<Props> = ({ data, className, deals, activeDeals, featureDeals, nameSource }) => {
  const [cookies, setCookies] = useCookies(["access", "refresh"]);
  const [isFavorite, setIsFavorite] = useState(data?.is_favorite);
  const [cartSources, setCartSources] = useState<any>();
  const [isInCart, setIsInCart] = useState(false);
  const { isMediaTablet } = useWindowWidth();
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    if (toJS(cartStore.cart)) {
      if (Object.keys(toJS(cartStore.cart)).length !== 0) {
        const newCart = toJS(cartStore.getActiveCart())
        newCart?.basket_project_service?.forEach((service: any) => {
          if (service.service.id === data.id) {
            setIsInCart(true);
            setCartSources(service.source);
          }
        });
      }
    }
  }, [cartStore.cart]);

  const changeFavorites = useFavorites(
    data,
    isFavorite,
    setIsFavorite,
    navigate
  );

  useEffect(() => {
    setIsFavorite(data?.is_favorite);
  }, [data]);

  const checkDiscount = (data?.has_active_discounts && Number(data.cost) !== Number(data.cost_with_discounts)) ? Number(data.cost_with_discounts) : Number(data.cost);

  const checkCurrency = () => {
    return userStore.user.currency?.char_code === "IDR" || userStore.user.currency?.char_code === "INR";
  }

  const formattedPrice = (price?: number | string, withMinus?: boolean) => <FormatPrice currency={userStore.user?.currency?.symbol} price={price} withMinus={withMinus} />;

  return (
    <>
    <div
      className={clsx(
        className && classes[className],
        classes["price-block"],
        deals.active && activeDeals && !featureDeals && classes.priceDeals
      )}
    >
      <div className={classes.price}>
        <div className={classes.text}>
          <PriceText data={data} t={t} asteriskSymbol />
        </div>
        {data?.is_free ?
          (<div className={classes.cost}>
            {t("Free")}
          </div>) :
          <div className={clsx(classes.cost, checkCurrency())}>
            <span>
              {formattedPrice(checkDiscount, false)}
            </span>
            {(data?.has_active_discounts && Number(data.cost) !== Number(data.cost_with_discounts)) && (
              <span className={clsx(classes.discount, checkCurrency())}>
                {formattedPrice(data.cost, false)}
              </span>
            )}
          </div>
        }
      </div>
      {deals?.active && activeDeals && !featureDeals && !isMediaTablet && (
        <div>
          <div className={classes.tooltipWrap}>
            <span className={classes.closeTimeInner}>
              {t("Closing time")}:{" "}
              <span className={classes.closeTime}>
                {deals.dateDealDate} {deals.monthEndDate}{" "}
                {deals.yearEndDate}{" "}
              </span>
            </span>
            <div className={classes.closeTimeTooltip}>
              <TooltipContainer
                text={t("Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled")}
                position={"top"}
                className={classes.tooltipContainer}
                classNameTooltip={classes.tooltip}
              >
                <InfoIcon fill="#A09CAC" width="18px" height="18px" />
              </TooltipContainer>
            </div>
          </div>
        </div>
      )}
      <div className={classes.buttons}>
        <AddToCartButton data={data} />
        <div className={classes.favored}>
          <Button
            className={classes.favorite}
            theme="icon-default"
            onClick={changeFavorites}
          >
            {isFavorite ? <FilledHeartIcon /> : <HeartIcon/>}
          </Button>
        </div>
      </div>
      {!data?.is_free && (
        <div className={classes.priceDesciption}>
          * {t("This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our ")}
          {' '}
          <NavLink
            to={'/pricing-disclaimer/'}
            state={{name: nameSource}}
            className={ classes.disclaimer }
          >
            {t("Pricing Disclaimer")}
          </NavLink>
        </div>
      )}
    </div>
   </>
  );
};
