import {FC, useCallback, useEffect, useState} from "react";

import classes from "./CartList.module.scss";
import {CartCard} from "../CartCard";
import {useMarketPlaceStore} from "contexts/marketPlaceContext";
import {observer} from "mobx-react-lite";
import {Checkbox} from "components/Checkbox/Checkbox";
import {useCartStore} from "contexts/CartContext";
import {useTranslation} from "react-i18next";
import {queryParamsHandler} from "../../CardsContainer/utils";
import { ICartData } from "types";

interface IProps {
  data: ICartData[],
  cartId: any,
  selectedItems: any[],
}
export const CartList: FC<IProps> = observer(({data, cartId, selectedItems}) => {

  const marketPlaceStore = useMarketPlaceStore();
  const cartStore = useCartStore();
  const {t} = useTranslation();
  const [disabled, setDisabled] = useState(cartStore?.isSelected);

  useEffect(() => {
    setDisabled(cartStore?.isSelected)
  }, [cartStore?.isSelected]);

  const handleAllCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(!disabled)
    cartStore.handleAllItemsInSelectedItem(data, event);
  }, [data]);

  const handleRemoveSelected = () => {
    cartStore.handleRemoveSelected();
  };

  useEffect(() => {
    const params = queryParamsHandler({isHot: true})
    marketPlaceStore.loadHotDeals(params)
  }, []);

  return (
    <div className={classes.list}>
      <div className={classes.top}>
        <Checkbox
          className={classes.checkbox}
          onChange={handleAllCheckboxChange}
          checked={selectedItems.length === data.length}
          label={t("Select all")}
          disabled={disabled}
        />

        <button className={classes.top__reset} onClick={handleRemoveSelected}>
          {t("Remove selected")}
        </button>


      </div>
      <div className={classes.cards}>
        {data?.map((item) => (
          <CartCard
            hotDeals={marketPlaceStore.dataTimeHotDeals}
            cartId={cartId}
            item={item}
            key={item.service.id}
            selectedItems={selectedItems}
          />
        ))}
      </div>
    </div>
  );
});