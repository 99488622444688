import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils";

export const FireIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.291 9.164a6.788 6.788 0 0 0-1.62-2.25l-.567-.521a.158.158 0 0 0-.254.064l-.254.729c-.159.457-.45.923-.862 1.382a.122.122 0 0 1-.08.04.108.108 0 0 1-.084-.03.115.115 0 0 1-.039-.094c.072-1.175-.279-2.502-1.049-3.945-.636-1.2-1.521-2.135-2.627-2.787l-.806-.475a.157.157 0 0 0-.235.143l.043.937c.03.641-.045 1.207-.22 1.678a5.57 5.57 0 0 1-.918 1.592 5.774 5.774 0 0 1-.928.9A6.887 6.887 0 0 0 3.832 8.9a6.792 6.792 0 0 0-.166 5.67 6.862 6.862 0 0 0 3.66 3.625A6.872 6.872 0 0 0 10 18.73c.928 0 1.826-.18 2.674-.533a6.809 6.809 0 0 0 2.185-1.459 6.761 6.761 0 0 0 2.016-4.822c0-.953-.195-1.879-.584-2.752Zm-2.365 6.627A5.537 5.537 0 0 1 10 17.401a5.537 5.537 0 0 1-3.926-1.61 5.424 5.424 0 0 1-1.62-3.875c0-.85.19-1.664.567-2.422A5.524 5.524 0 0 1 6.6 7.582a6.828 6.828 0 0 0 1.144-1.11A6.919 6.919 0 0 0 8.881 4.5c.109-.294.188-.598.236-.908.47.433.865.957 1.195 1.57.653 1.223.954 2.31.895 3.236a1.445 1.445 0 0 0 2.516 1.059 6.42 6.42 0 0 0 .671-.89c.274.349.502.73.684 1.14.31.7.469 1.443.469 2.209a5.42 5.42 0 0 1-1.621 3.875Z"
      fill="url(#paint0_linear_4449_92989)" />
    <defs>
      {RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_4449_92989" x1="-3.20984" y1="-7.13385" x2="14.5006" y2="9.05271" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFB3D2" />
          <stop offset="1" stop-color="#FF4848" />
        </linearGradient>

      }
      {!RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_4449_92989" x1="0.726601" y1="3.98518" x2="20.0487" y2="16.6272" gradientUnits="userSpaceOnUse">
          <stop stop-color="#4BA5FF" />
          <stop offset="1" stop-color="#A54BFF" />
        </linearGradient>
      }
    </defs>
  </svg>
);
