import classes from "./Finances.module.scss";
import { QueryParamTypes } from "../../constants/queryParamTypes";
import { formatPrice } from "../../components/FormatPriceAndCurrency";
import { getFormattedDate, getFormattedTime } from "tools/formattedDate";
import { userStore } from 'stores/userStore';
import { formatBalanceForLocale } from './formatBalanceForLocale';
import Invoices from './Invoices';
import { toJS } from 'mobx';

const getPaymentDateMessage = (
  invoice: any,
  paidDate: any,
  cancelledDate: any,
  diffDays: any,
  t: any,
) => {
  if (invoice.status === "PAID" && paidDate) {
    return t("Paid {{ dateString }}", {
      dateString: getFormattedDate(paidDate),
    });
  } else if (invoice.status === "PARTIALLY_PAID" && paidDate) {
    return t("Partially paid {{ dateString }}", {
      dateString: getFormattedDate(paidDate),
    });
  } else if (invoice.status === "CANCELLED") {
    return t("Cancelled {{dateString}}", {
      dateString: getFormattedDate(cancelledDate),
    });
  } else if (!diffDays.overdue && diffDays.difference) {
    return t("{{ dateString }} days for payment", {
      dateString: diffDays.difference,
    });
  } else if (diffDays.overdue) {
    return t("Overdue by {{ dateString }} days", {
      dateString: diffDays.difference,
    });
  } else if (!diffDays.overdue && !diffDays.difference) {
    return t("Due today");
  }
};

const getInvoiceBtnText = (stat: string) => {
  switch (stat) {
    case "PAID":
      return "Paid";
    case "CANCELLED":
      return "Cancelled";
    default:
      return "Pay now";
  }
};

export const columnsArray = (t: any) => [
  {
    header: "",
    accessor: "serialNumber",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t("Projects"),
    accessor: "projects",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t("Invoices"),
    accessor: "invoices",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t("Issued"),
    accessor: "issued",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t("Amount"),
    accessor: "amount",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t("Action"),
    accessor: "action",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
];

export const tableData = (invoices: any, t: any) =>
  invoices?.map((invoice: any) => {
    const invoiceDate = new Date(invoice?.created);
    const paidDate = invoice.paid_date ? new Date(invoice.paid_date) : null;
    const cancelledDate = invoice.cancelled_date
      ? new Date(invoice.cancelled_date)
      : null;
    const diffDays = invoice.diff_days;
    const invoiceTotalAmount = formatPrice(invoice?.total);

    return {
      serialNumber: {
        value: invoice.serial_number,
        className: classes.table__header,
      },
      projects: {
        value: invoice.project?.name,
        className: classes.table__header,
        link: `/projects/${invoice.project?.id}`,
        linkOptions: window.location.href.indexOf("finances") !== -1,
        description: {
          text: `ID ${invoice.project?.number}`,
          className: classes.table__text, 
        },
      },
      invoices: {
        value: invoice.invoice?.number,
        className: classes.table__header,
        filePath: invoice.file,
        description: {
          text: invoice.invoice?.description,
          className: classes.table__text,
        },
      },
      issued: {
        value: getFormattedDate(invoiceDate),
        className: classes.table__header,
        description: {
          text: getFormattedTime(invoiceDate),
          className: classes.table__text,
        },
      },
      amount: {
        value: formatBalanceForLocale(invoice.total, invoice.currency.char_code || userStore.user.currency.char_code, invoice.currency?.symbol),
        className: classes.table__header,
        description: {
          text: getPaymentDateMessage(
            invoice,
            paidDate,
            cancelledDate,
            diffDays,
            t,
          ),
          className: classes.table__text,
        },
      },
      action: {
        button: {
          status: invoice.status,
          text: t(getInvoiceBtnText(invoice.status)),
          disabled:
            invoice.status === "PAID" ||
            new Date(invoice.due_date) < new Date(),
          cancelled: invoice.status === "CANCELLED",
          className: classes.table__button,
          filePath: invoice.file,
        },
        serial_id: invoice.id,
        project_number: invoice.project?.number,
        amount: invoice?.total,
        project_name: invoice.project?.name,
      },
    };
  });

export const queryParamsHandler = ({
  page,
  pageSize,
  quickFilter,
}: QueryParamTypes) => {
  const queryParams = {
    page,
    page_size: pageSize,
    "quick-filter": quickFilter,
  };
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};
