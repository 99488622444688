import {FC, useEffect, useMemo, useState} from "react";

import classes from "./ProjectDealStages.module.scss";
import { CheckCirleIconPurple } from "components/Icons/CheckCirleIconPurple";
import { PurpleTimeIcon } from "components/Icons/PurpleTimeIcon";
import { PurpleEmptyIcon } from "components/Icons/PurpleEmptyIcon";

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { CancelledIcon } from "../../../components/Icons/CancelledIcon";
import { PausedIcon } from "../../../components/Icons/PausedIcon";
import { DEAL_STAGES } from "../../../constants/dealStatuses";
import { DownloadIcon } from "components/Icons/DownloadIcon";
import {StageType} from "../../Projects/DealStage";
import {userStore} from "../../../stores/userStore";
import {getLangName} from "../../../tools/utils";
import {useTranslation} from "react-i18next";

interface Props {
  project: any;
}

export const ProjectsDealStagesContainer: FC<Props> = observer(
  ({ project }) => {
    const {i18n} = useTranslation();
    const projectAllStage = project?.deal_stages
    const systemStages = project?.all_stages;
    const { PAUSED, CANCELLED, COMPLETED } = DEAL_STAGES;
    const [internalStageList, setInternalStageList] = useState<any>([])
    const userLocale = userStore.user?.language?.lang_code
    const pausedStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === PAUSED)
    const cancelledStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === CANCELLED)
    const completedStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === COMPLETED)

    const stageArray = useMemo(() => systemStages?.map((stage: StageType) => {
      if (
        stage?.type?.toLowerCase() === CANCELLED.toLowerCase() ||
        stage?.type?.toLowerCase() === PAUSED.toLowerCase() ||
        stage?.type?.toLowerCase() === COMPLETED.toLowerCase()
      ) {
        return null
      } else {
        return {
          name: stage.name,
          type: stage.type,
          description: stage.description,
        }
      }
    }), [CANCELLED, PAUSED, systemStages, COMPLETED])

    const orderStages = () => {
      const orderedStages = stageArray
        .map((stage: any) => {
          const findStage = projectAllStage.find((projectStage: any) => {
            return stage?.type?.toLowerCase() === projectStage?.type?.toLowerCase();
          });
          if (findStage) {
            return { ...findStage };
          } else {
            return { ...stage, id: undefined };
          }
        });

      let lastIndex = -1;
      for (let i = 0; i < orderedStages.length; i++) {
        if (orderedStages[i]?.id !== undefined) {
          lastIndex = i;
        }
      }

      // Вставляем стадии "Paused" и "Cancelled" на соответствующие позиции
      if (pausedStage) {
        orderedStages?.splice(lastIndex + 1, 0, {
          ...pausedStage,
          name: getLangName(pausedStage, "name", i18n),
        });
      }

      if (cancelledStage) {
        let index = pausedStage ? 2 : 1;
        orderedStages?.splice(lastIndex + index, 0, {
          ...cancelledStage,
          name: getLangName(cancelledStage, "name", i18n),
        });
      }

      if (completedStage) {
        orderedStages?.splice(lastIndex + 1, 0, {
          ...completedStage,
          name: getLangName(completedStage, "name", i18n),
        });
      }

      return orderedStages;
    };

    const formatDate = (dateString: string) => {
      const options: any = { year: "numeric", month: "short", day: "2-digit" };
      const date: Date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    };

    useEffect(() => {
      setInternalStageList(orderStages())
    }, [pausedStage, cancelledStage, userLocale, projectAllStage]);

    const getStepIcon = (
      stageName: string,
      stageType: string = "",
      id: number = 0,
      planEndDate: string = "",
      factEndDate: string = "",
    ) => {
      const projectFile = project.files.find((el: any) => el.deal_stage === id)
      const projectFilePath = projectFile?.file
      const helpText = stageArray.find(
        (obj: any) => obj?.name === stageName,
      )?.description;
    const fileName = projectFile?.file_name ? projectFile?.file_name : projectFilePath?.match(/\/files\/(.+)/)[1];
      const shortFileName =
        fileName?.length > 20 ? fileName?.slice(0, 20) + "..." : fileName;

      const additionalStageIcon = (stageType: string, isTimeIcon?: boolean) => {
        const type = stageType.toLowerCase();
        if (type === PAUSED) {
          return <PausedIcon />;
        } else if (type === CANCELLED) {
          return <CancelledIcon />;
        } else if (isTimeIcon) {
          return <PurpleTimeIcon />;
        } else {
          return <CheckCirleIconPurple />;
        }
      };

      if (planEndDate && factEndDate) {
        return (
          <div className={classes.step}>
            <div className={clsx(classes.name, classes.past)}>
              <span className={classes.step__date}>
                {formatDate(factEndDate)}
              </span>
              {additionalStageIcon(stageType)}
              <div className={classes.step__block}>
                <span>{stageName}</span>
                <p>{helpText}</p>
                {projectFilePath && (
                  <a
                    href={projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                  >
                    {shortFileName}
                    <DownloadIcon />
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      } else if (planEndDate && !factEndDate) {
        return (
          <div className={clsx(classes.step, classes.step_empty)}>
            <div className={clsx(classes.name, classes.past)}>
              <span className={classes.step__date}>
                {formatDate(planEndDate)}
              </span>
              {additionalStageIcon(stageType, true)}
              <div className={classes.step__block}>
                <span>{stageName}</span>
                <p>{helpText}</p>
                {projectFilePath && (
                  <a
                    href={projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                  >
                    {shortFileName}
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className={clsx(classes.step, classes.step_empty)}>
            <div className={clsx(classes.name, classes.past)}>
              <span className={classes.step__date}></span>
              <PurpleEmptyIcon />
              <div className={classes.step__block}>
                <span>{stageName}</span>
                <p>{helpText}</p>
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div className={classes.map}>
        {internalStageList?.map((el: any) => {
          if (el?.id){
            return getStepIcon(el?.name, el?.type, el.id, el.plan_end_date, el.fact_end_date)
          } else {
            return el?.name && getStepIcon(el.name)
          }
        })}
      </div>
    );
  },
);
