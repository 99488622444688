import React, { FC, useLayoutEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthStore } from "contexts/AuthContext";
import { RequiredAuthProps } from "components/RedirectAuth";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { LoginErrorContainer } from "containers/LoginError";
import { ErrorPageContainer } from 'components/ErrorPageContainer';

export const LoginFromKID: FC<RequiredAuthProps> = observer(() => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  console.log('authStore', authStore)

  useLayoutEffect(() => {
    const checkToken = async () => {
      setIsLoading(true);

      let tokenIsOk: string | Boolean = false;
      if (token) {
        tokenIsOk = await authStore.checkToken(token);
      }
      
      if (toJS(authStore).isAuth) {
        navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, {
          replace: true,
        });
      } else if (tokenIsOk === true) {
        navigate("/register", { replace: true });
      } else {
        setError(tokenIsOk as string);
      }

      setIsLoading(false);
    };

    checkToken();
  }, [token]);

  if (isLoading) return null

  return (
    <>
      <ErrorPageContainer />
    </>
  );
});
