import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import { Button } from "components/Button";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import classes from "./LoginRu.module.scss";
import { t } from "i18next";
import { NavLink } from "react-router-dom";
import { Link } from "components/Link";
import logo_ru from "img/logo_ru.svg";
import { LoginRuBGRightIcon } from "components/Icons/LoginRuBGRightIcon";
import { LoginRuBGLeftIcon } from "components/Icons/LoginRuBGLeftIcon";

export const LoginContainerRu: FC = observer(() => {
  const loginWithKokocID = (event: Event) => {
    event.preventDefault();
    window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_SITE_ID}&3`;
  };

  return (
    <>
      <TitleAndDescription pageName="Login" />

      <div className={classes.container}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo_ru} alt="" className={classes.logo} />
        </NavLink>

        <div className={classes.form}>
          <div className={classes.title}>
            {t("Kokoc Group platform").toLocaleUpperCase()}
          </div>

          <div className={classes.description}>
            &mdash; {t("comprehensive solutions for your business development")}
          </div>

          <div
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`)
            }
            style={{ width: "10px", height: "10px", cursor: "pointer" }}
          />
          {/* <div className={classes.text}>
            <span>
              {t("Don’t have an account")}?
              <div className={classes.link}>
                <a
                  href={`${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`}
                >
                  {t("Sign up")}
                </a>
              </div>
            </span>
          </div> */}

          <Button
            className={clsx(classes.button, classes["submit-button"])}
            theme="light"
            onClick={loginWithKokocID}
          >
            {t("Login with Kokoc ID")}
          </Button>
        </div>

        <div className={classes.right}>
          <LoginRuBGRightIcon />
        </div>

        <div className={classes.left}>
          <LoginRuBGLeftIcon />
        </div>
      </div>
    </>
  );
});
