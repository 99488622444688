import {FC, useEffect, useMemo, useState} from "react";

import classes from "./ProjectDealStages.module.scss";
import { CheckCirleIconPurple } from 'components/Icons/CheckCirleIconPurple';
import { PurpleTimeIcon } from 'components/Icons/PurpleTimeIcon';
import { PurpleEmptyIcon } from 'components/Icons/PurpleEmptyIcon';

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { CancelledIcon } from "../../../components/Icons/CancelledIcon";
import { PausedIcon } from "../../../components/Icons/PausedIcon";
import { DownloadIcon } from "components/Icons/DownloadIcon";
import {useTranslation} from "react-i18next";
import {DEAL_STAGES} from "../../../constants/dealStatuses";
import {Button} from "../../../components/Button";
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import {NavLink} from "react-router-dom";
import {userStore} from "../../../stores/userStore";
import Lottie from "lottie-react";
import loader from "../../../components/PageLoader/loader.json";
import {TooltipContainer} from "../../../components/Tooltip/TooltipContainer";

export type StageType = {
  name: string,
  type: string,
  description: string
}

interface Props {
  project: any
}

export const ProjectsDealStagesContainer: FC<Props> = observer(({ project }) => {
  const { isMediaTablet } = useWindowWidth();
  const { t } = useTranslation();
  const { CANCELLED, PAUSED } = DEAL_STAGES
  const projectAllStage = project?.deal_stages;
  const systemStages = project?.all_stages;
  const pausedStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === PAUSED)
  const cancelledStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === CANCELLED)
  const [internalStageList, setInternalStageList] = useState<any>([])
  const userLocale = userStore.user?.language?.lang_code
  const isInnerDataLoading = userStore?.isInnerDataLoading

  const moreButton = (
    <div className={classes.buttonContainer}>
      <div className={clsx(classes[`${isMediaTablet ? 'mobileContent' : 'content'}`])}>
        <NavLink to={`/projects/${project.id}`}>
          <Button
            theme="light"
          >
            {t("See more")}
          </Button>
        </NavLink>
      </div>

    </div>
  )

  const stageArray = useMemo(() => systemStages?.map((stage: StageType) => {
    if (stage?.type?.toLowerCase() === CANCELLED.toLowerCase() || stage?.type?.toLowerCase() === PAUSED.toLowerCase()) {
      return null
    } else {
      return {
        name: stage.name,
        type: stage.type
      }
    }
  }), [CANCELLED, PAUSED, systemStages])

  const orderStages = () => {
    const orderedStages = stageArray
      .map((stage: any) => {
        const findStage = projectAllStage.find((projectStage: any) => {
          return stage?.type?.toLowerCase() === projectStage?.type?.toLowerCase();
        });
        if (findStage) {
          return { ...findStage };
        } else {
          return { ...stage, id: undefined };
        }
      });

    let lastIndex = -1;
    for (let i = 0; i < orderedStages.length; i++) {
      if (orderedStages[i]?.id !== undefined) {
        lastIndex = i;
      }
    }

    // Вставляем стадии "Paused" и "Cancelled" на соответствующие позиции
    if (pausedStage) {
      orderedStages.splice(lastIndex + 1, 0, pausedStage);
    }

    if (cancelledStage) {
      let index = pausedStage ? 2 : 1
      orderedStages.splice(lastIndex + index, 0, cancelledStage);
    }

    return orderedStages?.slice(0, isMediaTablet ? 5 : 6);
  };


  useEffect(() => {
    setInternalStageList(orderStages())
  }, [pausedStage, cancelledStage, userLocale, projectAllStage]);

  const formatDate = (dateString: string) => {
    const options: any = { year: 'numeric', month: 'short', day: '2-digit' };
    const date: any = dateString ? new Date(dateString): null;
    return date?.toLocaleDateString('en-US', options);
  };

  const getStepIcon = (stageName: string, type: string = '', id: number = 0, planEndDate: string = '', factEndDate: string = '') => {
    const projectFile = project.files.find((el: any) => el.deal_stage === id)
    const projectFilePath = projectFile?.file
    const fileName = projectFile?.file_name ? projectFile?.file_name : projectFilePath?.match(/\/files\/(.+)/)[1];
    const shortFileName = fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName

    const stageType = type.toLowerCase();

    if (planEndDate && factEndDate) {
      return (
        <div className={classes.step}>
          <div className={clsx(classes.name, classes.past)}>
            {stageType === CANCELLED ? <CancelledIcon /> : stageType === PAUSED ? <PausedIcon /> : <CheckCirleIconPurple />}
            <div className={classes.name__main}>
              <span>{stageName}</span>
              {projectFilePath && (
                <a href={projectFilePath} target='_blank' className={classes.map__link} title={fileName}>
                  <span>{shortFileName}</span>
                  <DownloadIcon />
                </a>
              )}
            </div>
          </div>
          <TooltipContainer
            text={t("Fact date")}
            className={classes.tooltipContainer}
            classNameTooltip={classes.tooltip}
            tooltipInner={classes.tooltipInner}
            position='bottom'
          >
            <div className={classes.date}>{formatDate(factEndDate)}</div>
          </TooltipContainer>
        </div>
      )
    } else if (planEndDate && !factEndDate) {
      return (
        <div className={clsx(classes.step, classes.empty)}>
          <div className={clsx(classes.name, classes.past)}>
            {stageType === CANCELLED ? (
              <CancelledIcon />
            ) : stageType === PAUSED ? (
              <PausedIcon />
            ) : (
              <PurpleTimeIcon/>
            )}
            <div className={classes.name__main}>
              <span>{stageName}</span>
              {projectFilePath && (
                <a
                  href={projectFilePath}
                  target="_blank"
                  className={classes.map__link}
                  title={fileName}
                >
                  <span>{shortFileName}</span>
                  <DownloadIcon />
                </a>
              )}
            </div>
          </div>
          <TooltipContainer
            text={t("Plan date")}
            className={classes.tooltipContainer}
            classNameTooltip={classes.tooltip}
            tooltipInner={classes.tooltipInner}
            position="bottom"
          >
            <div className={classes.date}>
              {formatDate(planEndDate)}
            </div>
          </TooltipContainer>
        </div>
      );
    } else {
      return (
        <div className={clsx(classes.step, classes.empty)}>
          <div className={clsx(classes.name)}>
            <PurpleEmptyIcon />

            <div className={classes.name__main}>
              <span>{stageName}</span>
              {projectFilePath && (
                <a href={projectFilePath} target='_blank' className={classes.map__link} title={fileName}>
                  <span>{shortFileName}</span>

                  <DownloadIcon />
                </a>
              )}
            </div>

          </div>
          <TooltipContainer
            text={t("Plan date")}
            className={classes.tooltipContainer}
            classNameTooltip={classes.tooltip}
            tooltipInner={classes.tooltipInner}
            position="bottom"
          >
            <div className={classes.date}>
              {formatDate(planEndDate)}
            </div>
          </TooltipContainer>
        </div>
      )
    }
  }

  return (
    <div className={classes.map}>
      {isInnerDataLoading ? (
        <div className={classes.loader}>
          <Lottie animationData={loader} />
        </div>
      ) : (
        <>
          {internalStageList?.map((el: any) => {
            if (el.id){
              return getStepIcon(el.name, el.type, el.id, el.plan_end_date, el.fact_end_date)
            } else {
              return getStepIcon(el.name)
            }
          })}
          {moreButton}
        </>
      )}
    </div>
  )
});
