import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {
  Route,
  Navigate,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  defer,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Registration } from './pages/Registration';
import { Login } from 'pages/Login';
import { LoginFromKID } from "pages/LoginFromKID";
import { NotFoundPage } from 'pages/NotFoundPage';
import { routers } from 'constants/routers';
import { Restore } from 'pages/Restore';
import { NewPassword } from 'pages/NewPassword';
import { MarketPlace } from 'pages/MarketPlace';
import { Account } from 'pages/Account';
import { ServiceDetail } from 'pages/ServiceDetail';
import { Projects } from 'pages/Projects';
import { Finances } from 'pages/Finances';
import { Favorites } from 'pages/Favorites';
import { Cart } from 'pages/Cart';
import { SearchResults } from 'pages/SearchResults';
import { Disclaimer } from 'pages/Disclaimer';
import { HotDealsPage } from 'pages/HotDeals';
import { DashboardPage } from 'pages/Dashboard';
import { authStore } from './stores/authStore';
import { AuthRoute } from './components/AuthRoute';
import { ProtectRoute } from './components/ProtectRoute';
import { ConfirmEmail } from './components/ConfirmEmail';
import { CheckLocal } from './components/CheckLocal';
import { BaseTemplate } from './components/BaseTemplate';
import { RedirectAuth } from './components/RedirectAuth';
import { LoaderWrapper } from 'components/LoaderWrapper';
import Hotjar from '@hotjar/browser';
import useCookieConsent from 'hooks/useCookieConsent';
import * as Sentry from '@sentry/react';
import { ProjectsDetail } from 'pages/ProjectsDetail';
import { requestData } from './utils';
import { userStore } from './stores/userStore';
import { RUSSIAN_LOCALE } from './utils';
import { LoaderWrapperCart } from 'components/LoaderWrapperCart';
import { LoaderWrapperCardsContainer } from 'components/LoaderWrapperCardsContainer';
import { GetUser } from 'api/getUser';


const RoutesJSX = (
  <Route
    path=''
    element={<AuthRoute />}
    loader={async () => {
      const isAccessToken = authStore.accessToken;
      return defer(
        isAccessToken
          ? {}
          : {
              refresh: await authStore.refreshAuth(),
            }
      );
    }}
  >
    <Route element={<RedirectAuth />}>
      <Route path='/register' element={<Registration />} />
      <Route path='/login' element={<Login />} />
      <Route path='/restore' element={<Restore />} />
      <Route path='/new-password' element={<NewPassword />} />
      <Route path='/kid' element={<LoginFromKID />} />
    </Route>
    <Route element={<ProtectRoute />}>
      <Route
        element={<BaseTemplate />}
        loader={async () => {
          const options = {
            user: true,
            types: true,
          };
          return defer({
            loader: requestData(options),
          });
        }}
      >
        <Route path='*' element={<NotFoundPage />} />
        <Route
          path='/dashboard'
          element={<DashboardPage />}
          loader={async () => {
            const options = {
              user: true,
              projects: true,
            };
            return defer({
              loader: requestData(options),
            });
          }}
        />
        <Route element={<ConfirmEmail />}>
          <Route element={<CheckLocal />}>
            <Route path='/' element={<Navigate to='/solutions' />} />
            <Route
                path="/marketplace"
                element={<Navigate to="/solutions" />}
            />
            <Route
              path='/solutions'
              element={
                <LoaderWrapperCardsContainer title={'Solutions'}>
                  <MarketPlace />
                </LoaderWrapperCardsContainer>
              }
              loader={async () => {
                const options = {
                  // services: true,
                  user: true,
                  types: true,
                  cart: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/solutions/:id'
              element={
                <LoaderWrapper>
                  <ServiceDetail />
                </LoaderWrapper>
              }
              loader={async ({ params }) => {
                const options = {
                  serviceItem: true,
                  user: true,
                  services: true,
                  increment: true,
                  cart: true
                };
                return defer({
                  loader: requestData(options, params),
                });
              }}
            />
            <Route
              path='/solutions/app_store_optimization'
              element={
                <LoaderWrapper>
                  <ServiceDetail />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  auth: true,
                  cart: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/account/'
              element={
                <LoaderWrapper>
                  <Account />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  auth: true,
                  cart: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/account/personal'
              element={
                <LoaderWrapper>
                  <Account section={'personal'} />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  auth: true,
                  cart: true,
                  services: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/account/business'
              element={
                <LoaderWrapper>
                  <Account section={'business'} />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  auth: true,
                  cart: true,
                  services: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/account/change-password'
              element={
                <LoaderWrapper>
                  <Account section={'change-password'} />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  auth: true,
                  cart: true,
                  services: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/projects/'
              element={
                <LoaderWrapper>
                  <Projects />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  auth: true,
                  projects: true,
                  cart: true,
                  services: true,
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/projects/:id'
              element={
                <LoaderWrapper>
                  <ProjectsDetail />
                </LoaderWrapper>
              }
              loader={({ params }) => {
                const options = {
                  user: true,
                  auth: true,
                  projectInvoices: true,
                  projectInfo: true,
                  cart: true,
                  services: true,
                };
                return defer({
                  loader: requestData(options, params),
                });
              }}
            />
            <Route
              path='/finances/'
              element={
                <LoaderWrapper>
                  <Finances />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  auth: true,
                  user: true,
                  invoices: true,
                  cart: true,
                  services: true,
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path='/favorites/'
              element={
                <LoaderWrapperCardsContainer title={'Favorites'}>
                  <Favorites />
                </LoaderWrapperCardsContainer>
              }
              loader={async () => {
                const options = {
                  user: true,
                  services: true,
                  types: true,
                  cart: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path={routers.cart}
              element={
                <LoaderWrapperCart>
                  <Cart />
                </LoaderWrapperCart>
              }
              loader={async () => {
                const options = {
                  user: true,
                  cart: true,
                  services: true,
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route
              path={routers.hotdeals}
              element={
                <LoaderWrapper>
                  <HotDealsPage />
                </LoaderWrapper>
              }
              loader={async () => {
                const options = {
                  user: true,
                  services: true,
                  cart: true
                };
                return defer({
                  loader: requestData(options),
                });
              }}
            />
            <Route path={routers.disclaimer} element={<Disclaimer />} />
            <Route path={routers.searchResults} element={<SearchResults />} />
          </Route>

        </Route>
      </Route>
    </Route>
  </Route>
);

let router = '';
const routes = createRoutesFromElements(RoutesJSX);

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY}`,

    tracesSampleRate: 1.0,
  });
  console.log('sentry on');
}
router = createBrowserRouter(routes);

const App = () => {
  const analyticsCookieAccepted = useCookieConsent('platform_analytics');
  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  if (
    process.env.REACT_APP_SHOW_ANALYTICS === 'true' &&
    analyticsCookieAccepted
  ) {
    const siteId = 3572452;
    const hotjarVersion = 6;

    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });

    Hotjar.init(siteId, hotjarVersion);
    console.log('analytic on');
  } else {
    console.log('analytic off');
  }

  useEffect(() => {
    const shouldStartOnboarding = process.env.REACT_APP_ONBOARDING === 'true';
    if (!shouldStartOnboarding) return;
    localStorage.setItem('onboarding', 'true');
    const onboarding = localStorage.getItem('onboarding');
    onboarding && userStore.enableOnboarding(!!onboarding);
    return () => {
      localStorage.removeItem('onboarding');
    };
  }, []);


  const createFreescoutScript = async(access, refresh) => {
    const currentUser = (access && refresh) ? await GetUser(access, refresh) : null;
    const shouldLoadScript = process.env.REACT_APP_LOAD_FREESCOUT === 'true';
    if (shouldLoadScript) {
      const scriptConfig = document.createElement('script');
      scriptConfig.type = 'text/javascript';
      const newObject = {
        color: "#6E3CFF",
        position: "br",
        locale: "en",
        require: ["name", "email"],
        id: 2590262122
      }
      const visitorName = currentUser?.full_name;
      const visitorEmail = currentUser?.email;
      const visitorPhone = currentUser?.phone_number;
      const language = currentUser?.language;

      if (visitorName) newObject.visitor_name = visitorName;
      if (visitorEmail) newObject.visitor_email = visitorEmail;
      if (visitorPhone) newObject.visitor_phone = visitorName;
      if (language?.lang_code) newObject.locale = language?.lang_code;
      scriptConfig.innerHTML = `
          var FreeScoutW = {
            s: ${JSON.stringify(newObject)}
      }`;

      const scriptElement = document.createElement('script');
      scriptElement.src = "https://freescoutqa.kokoc.com/modules/chat/js/widget.js?v=2927";
      scriptElement.async = true;
      scriptElement.id = "freescout-w";

      document.head.appendChild(scriptConfig);
      document.head.appendChild(scriptElement);
    }
  }

  useEffect(() => {
        createFreescoutScript(cookies.access, cookies.refresh);
   }, []);

  return (
    <>
      <div
        className={RUSSIAN_LOCALE ? 'app__wrap rulocale' : 'app__wrap enlocale'}
      >
        <RouterProvider router={router} />
        <div className='app__toast'>
          <ToastContainer
            autoClose={5000}
            draggable={false}
            hideProgressBar={true}
            closeButton={false}
          />
        </div>
      </div>
    </>
  );
};

export default App;
