import { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';

import { Request } from 'tools/request';

import { Type } from 'components/Type';
import { Grid, GridItem } from 'components/Grid';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import { useTranslation } from 'react-i18next';

import classes from './Business.module.scss';
import { Notice } from 'components/Notice';
import { useUserStore } from 'contexts/UserContext';
import { ShowNotification } from 'tools/showNotification';
import { MultiselectComponent } from 'components/MultiselectComponent';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TooltipContainer } from '../../../components/Tooltip/TooltipContainer';
import { InfoIcon } from '../../../components/Icons/InfoIcon';
import { urlCheckReg } from '../validationSchemas';
import * as yup from 'yup';
import Service from "../../../services/service";
import {getLangName} from "../../../tools/utils";
import { getLinkForPrivacy } from '../getLinkForPrivacy';

interface Props {
  defaultValue: {
    companyName: string;
    companyLink: string;
    companySize: string;
    businessType: string;
  };
  getParams: any;
}

export const Business: FC<Props> = ({ defaultValue, getParams }) => {
  const { t, i18n } = useTranslation();

  const companySizesList = useMemo(
    () => [
      {
        id: "MICRO",
        name: t("Micro Business"),
        description: t("1-10 employees"),
        size: 1,
      },
      {
        id: "SMALL",
        name: t("Small Business"),
        description: t("11-50 employees"),
        size: 2,
      },
      {
        id: "MEDIUM",
        name: t("Medium-Sized Business"),
        description: t("51-250 employees"),
        size: 3,
      },
      {
        id: "LARGE",
        name: t("Large Business"),
        description: t("251-1000 employees"),
        size: 4,
      },
      {
        id: "CORPORATION",
        name: t("Corporation"),
        description: t("more than 1000 employees"),
        size: 5,
      },
    ],
    [],
  );

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t("This field is required")),
    url: yup
      .string()
      .matches(urlCheckReg, t("Invalid link"))
      .required(t("This field is required")),
    size: yup.string(),
    industry: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,

    setValue,
    trigger,
  } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });

  const formRef = useRef(null);

  const [cookies, setCookies] = useCookies(["access", "refresh"]);
  const userStore = useUserStore();

  const [companyName, setCompanyName] = useState<string>(
    defaultValue?.companyName || "",
  );

  const { companySize = "", businessType = "" } = defaultValue ?? {};

  const [companyLink, setCompanyLink] = useState<string>(
    defaultValue?.companyLink || "",
  );
  const [businessTypes, setBusinessTypes] = useState<any>([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState<any>();
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>();
  const [isFieldEmpty, setIsFieldEmpty] = useState({
    size: false,
    industry: false,
  });

  const [notice, setNotice] = useState({ type: "", text: "" });

  const [disableSubmit, setDisableSubmit] = useState(true);
  const getBusinessTypesInfo = useCallback(async () => {
    try {
      const response: any = await Service.getBusinessTypes();
      const data = response?.data?.results.map((item: any) => {
        return {
          id: item.id,
          name: getLangName(item,'name', i18n),
        };
      })
      setBusinessTypes(data);

      if (defaultValue.businessType) {
        const businessType = response?.data?.results?.find(
          (item: any) => item.id === defaultValue.businessType
        );
        setSelectedBusinessType(businessType);
        setValue('industry', businessType[`name_${i18n.language}`]);

        trigger();
      }
    } catch (error) {
      setBusinessTypes([]);
    }
  }, []);

  useEffect(() => {
    notice && getParams();
  }, []);

  useEffect(() => {
    if (
      defaultValue &&
      companyName &&
      companyLink &&
      selectedCompanySize &&
      selectedBusinessType &&
      (defaultValue.companyName !== companyName ||
        defaultValue.companyLink !== companyLink ||
        defaultValue.companySize !== selectedCompanySize.id ||
        defaultValue.businessType !== selectedBusinessType.id)
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [
    defaultValue,
    companyName,
    companyLink,
    selectedCompanySize,
    selectedBusinessType,
  ]);

  useEffect(() => {
    if (!companyName)
      setError("companyName", {
        type: "server",
        message: t("This field is required"),
      });
    if (!companyLink)
      setError("url", { type: "server", message: t("This field is required") });
  }, [companyLink, companyName, setError, t]);

  useEffect(() => {
    setIsFieldEmpty({
      size: !selectedCompanySize && !companySize,
      industry: !selectedBusinessType && !businessType,
    });
  }, [selectedCompanySize, selectedBusinessType, companySize, businessType]);

  useEffect(() => {
    getBusinessTypesInfo();

    setValue('companyName', defaultValue.companyName);
    setValue('url', defaultValue.companyLink);
    // setValue('size', defaultValue.companySize);
    // setValue('industry', defaultValue.businessType);
  }, []);

  const submit = async (e: any) => {
    let formValues = new FormData();

    formValues.append("company_name", companyName);
    formValues.append("company_link", companyLink);
    (selectedCompanySize?.id || companySize) &&
      formValues.append("company_size", selectedCompanySize?.id || companySize);
    (selectedBusinessType?.id || businessType) &&
      formValues.append(
        "company_industry",
        selectedBusinessType?.id || businessType,
      );

    try {
      const result = await Request({
        type: "PUT",
        url: `${process.env.REACT_APP_API}/api/v1/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      userStore.addUser(result?.user);
      ShowNotification({
        type: "success",
        children: "Profile successfully changed",
      });
      setDisableSubmit(true);
    } catch {
      ShowNotification({ type: "error", children: "Something went wrong" });
    }
  };

  const { size, industry } = isFieldEmpty;

  return (
    <>
      <TitleAndDescription pageName="Account/business" />
      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={"h2"} className={classes.title}>
            {t("Business information")}
          </Type>
        </div>
        <p className={classes["info"]}>
          {t(
            "By providing Us with Your contact information You further agree on and accept the terms of our",
          )}{" "}
          <a
            className={classes['info__link']}
            href={getLinkForPrivacy(i18n.language)}
            target='_blank'
          >
            {t('Privacy Policy (personal)')}
          </a>
        </p>

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(submit)}
        >
          <div className={classes.box}>
            <Grid direction={"row"} className={classes.row}>
              <GridItem
                col={{ default: 12, md: 6 }}
                className={classes["input-field"]}
              >
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }: { field: any }) => (
                    <Input
                      label={t("Company Name")}
                      labelPosition={"left"}
                      type={"text"}
                      placeholder={t("Enter company name")}
                      error={!!errors.companyName}
                      value={companyName || field.value}
                      onChange={e => {
                        setCompanyName(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.companyName && (
                  <TooltipContainer
                    text={errors.companyName?.message}
                    customClasses={"kit-ui-block"}
                    position={"top"}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
              </GridItem>

              <GridItem
                col={{ default: 12, md: 6 }}
                className={classes["input-field"]}
              >
                <Controller
                  name="url"
                  control={control}
                  render={({ field }: { field: any }) => (
                    <Input
                      label={t("Company link")}
                      labelPosition={"left"}
                      type={"text"}
                      placeholder={t("Company link")}
                      value={field.value || companyLink}
                      error={!!errors.url}
                      onChange={e => {
                        setCompanyLink(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.url && (
                  <TooltipContainer
                    text={errors.url?.message}
                    customClasses={"kit-ui-block"}
                    position={"top"}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
              </GridItem>
            </Grid>

            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes["item-label"]}>{t("Company size")}</div>
                <Controller
                  name="size"
                  control={control}
                  render={() => (
                    <MultiselectComponent
                      data={companySizesList}
                      defaultSelected={companySize}
                      placeholder=""
                      setMultiselect={setSelectedCompanySize}
                      search={false}
                      isFieldEmpty={size}
                      isCompanySize
                    />
                  )}
                />
              </GridItem>
            </Grid>

            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes["item-label"]}>
                  {t("Company industry")}
                </div>
                <Controller
                  name="industry"
                  control={control}
                  render={() => (
                    <MultiselectComponent
                      data={businessTypes}
                      defaultSelected={businessType}
                      placeholder=""
                      setMultiselect={setSelectedBusinessType}
                      search={false}
                      isFieldEmpty={industry}
                    />
                  )}
                />
              </GridItem>
            </Grid>
          </div>

          <div className={classes.submit}>
            <Button
              className={classes.button}
              theme="primary"
              disabled={disableSubmit || !isValid}
              size="middle"
              type="submit"
            >
              {t("Save changes")}
            </Button>
          </div>
        </form>

        {notice.type !== "" && (
          <div className={classes.notice}>
            <Notice type={notice.type}>{notice.text}</Notice>
          </div>
        )}
      </div>
    </>
  );
};
