import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import classes from "./CartCard.module.scss";
import clsx from "clsx";
import { Button } from "components/Button";
import { WhiteFireIcon } from "components/Icons/WhiteFireIcon";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { HotDealsCounter } from "containers/CardsContainer/HotDeals/HotDealsCounter";
import { useTranslation } from "react-i18next";
import { useHotDeals, useFavorites } from "hooks";
import { useUserStore } from "contexts/UserContext";
import { Checkbox } from "components/Checkbox/Checkbox";
import { useCartStore } from "contexts/CartContext";
import FormatPrice, {
  formatPrice,
} from "../../../components/FormatPriceAndCurrency";
import { priceTextContent } from "../../../components/PriceText";
import { useBreakPoints } from "./hooks/useBreakPoints";
import UiIcon from "../../../components/Icon";
import { ICartData, IDiscount } from "types";
import { DeleteConfirmationModal } from "./ui/modals/DeleteConfirmationModal";
import { PriceInfoModal } from "./ui/modals/PriceInfoModal";
import { HeartBoldIcon, HeartBoldFilledIcon } from "components/Icons/HeartIcon";
import { TrashIcon } from "components/Icons/TrashIcon";
import { Fader } from "components/Fader";
import { PlusIcon } from "components/Icons/PlusIcon";
import { MinusIcon } from "components/Icons/MinusIcon";
import classnames from "classnames";

interface Props {
  hotDeals: {
    activeDeals: boolean;
    featureDeals: boolean;
    endDate: Date;
    dayDeals: number;
    hourDeals: number;
    minutesDeals: number;
    startDate: Date;
  };
  item: ICartData;
  cartId: any;
  selectedItems: any[];
}

export const CartCard: FC<Props> = ({ hotDeals, item, selectedItems }) => {
  const { t, i18n } = useTranslation();
  const userStore = useUserStore();
  const cartStore = useCartStore();

  // eslint-disable-next-line
  // @ts-ignore
  const name = item.service[`name_${i18n.language}`] ?? item.service.name;
  const total = Number(item.service?.is_free ? "0" : item.total_cost);
  const totalWithDiscounts = Number(item.total_cost_with_discounts);
  const {
    product: { name: productName },
    is_new: isNew,
    slug: slug,
    is_free: free,
    is_favorite: favorite,
    id: id,
    discounts: discounts,
  } = item.service;
  const currency = userStore?.user?.currency;

  const [isFavorite, setIsFavorite] = useState(favorite);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isDisabledButton, setDisabledButton] = useState(false);

  const { getHotDealText } = useHotDeals(t);
  const [hotDealDiscountText, setHotDealDiscountText] = useState("");
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const { isTablet, isHovered, setIsHovered } = useBreakPoints();
  const [disabled, setDisabled] = useState(cartStore?.isSelected);

  const data = {
    slug,
    id,
  };

  const priceToText =
    priceTextContent(item.service, t, false, true)
      ?.split("_")
      .join(" ")
      .toLowerCase() || "";

  const changeFavorites = useFavorites(
    data,
    isFavorite,
    setIsFavorite,
    navigate,
  );

  const deals = discounts.find((item: IDiscount) => {
    return item.type === "HOT_DEAL" && item.is_active === true;
  });

  const utcDateDeal = new Date(`${deals?.deal_end_date}`);
  const dayEndDeal = utcDateDeal.getDate();
  const monthEndDeal = utcDateDeal.toLocaleString(i18n.language, {
    month: "long",
  });
  const yearEndDeal = utcDateDeal.getFullYear();

  const isCart = window.location.pathname === "/cart";

  useEffect(() => {
    cartStore.modalOpen = openModal;
    if (isCart) {
      cartStore.setFixedButtonSize(openModal ? 56 : 120);
    }
    return () => {
      cartStore.setFixedButtonSize(56);
    };
  }, [cartStore, openModal, isCart]);

  useEffect(() => {
    setDisabled(cartStore?.isSelected);
  }, [cartStore?.isSelected]);

  useEffect(() => {
    if (item?.service)
      setHotDealDiscountText(getHotDealText(deals, item.service));
  }, [deals, item]);

  const handleOpenModal = () => {
    setOpenPriceModal(true);
  };

  const handleCloseModal = () => {
    setOpenPriceModal(false);
  };

  const addToCart = async () => {
    setDisabledButton(true);
    try {
      await cartStore.addToCart({ serviceId: item.service.id });
    } finally {
      setDisabledButton(false);
    }
  };

  const removeFromCart = async () => {
    setDisabledButton(true);
    try {
      await cartStore.removeFromCart({ serviceId: item.service.id });
    } finally {
      setDisabledButton(false);
    }
  };

  const deleteFromCart = async () => {
    setDisabledButton(true);
    try {
      await cartStore.removeFromCart({
        serviceId: item.service.id,
        count: item.count,
      });
      setOpenModal(false);
    } finally {
      setDisabledButton(false);
    }
  };

  const totalNoDecimal =
    total < 1 && total > 0 ? Math.ceil(total) : Math.floor(total);
  const discountNoDecimal =
    totalWithDiscounts < 1 && totalWithDiscounts > 0
      ? Math.ceil(totalWithDiscounts)
      : Math.floor(totalWithDiscounts);

  const hasDiscount = Number(discountNoDecimal) !== Number(totalNoDecimal);

  const formattedTotalWithDiscounts = formatPrice(discountNoDecimal);
  const formattedTotal = formatPrice(total);
  const itemCount = item?.count || 1;
  const priceUnitText =
    itemCount > 1
      ? `${priceToText === "all" ? "" : priceToText}`
      : priceToText;

  const cardCostWithText =
    Number(totalNoDecimal) && Number(discountNoDecimal)
      ? ` / ${priceUnitText}`
      : ` / ${priceUnitText}`;

  const cardCostWithNoDiscountWithText = hasDiscount ? ` / ${priceToText}` : "";

  let totalCostNumber = 0;
  let totalCostWithNoDiscountNumber = 0;

  cartStore.cart.basket_project_service.forEach((service: any) => {
    if (service.service.id === id) {
      totalCostWithNoDiscountNumber += Number(service.total_cost);
      totalCostNumber += Number(service.total_cost_with_discounts);
    }
  });

  const cardCost = itemCount > 1 ? "" : cardCostWithText;
  const cardCostWithNoDiscount =
    itemCount > 1
      ? hasDiscount && formatPrice(totalCostWithNoDiscountNumber)
      : cardCostWithNoDiscountWithText;

  cartStore.updateCardCost(totalNoDecimal, item.service, discountNoDecimal);

  const longCardCostMobile = String(cardCost)?.length > 14;
  const longCardCost = String(cardCost)?.length > 10;
  const longCardCostDesktop = String(cardCost)?.length > 10;

  const modalContent = () => {
    const text = t(
      "You’ll get the final price after consultation with a manager",
    );
    const words = text.split(" ");
    const firstPart = words.slice(0, 5).join(" ");
    const secondPart = words.slice(5).join(" ");
    return firstPart + "<br />" + secondPart;
  };

  const renderPrice = (
    <div className={classes.price}>
      <div className={classes.price__firstBlock}>
        <div className={clsx(classes.price__total)}>
          <div
            className={clsx(
              classes.price__variations,
              longCardCostMobile && classes.price__longTotalMobile,
              longCardCost && classes.price__longTotal,
            )}
          >
            <div
              className={clsx(
                longCardCostMobile && classes.price__longTotalMobile,
                longCardCost && classes.price__longTotal,
                longCardCostDesktop && classes.price__longTotalDesktop,
              )}
            >
              <span style={{ display: "inline-block" }}>
                {!free && (
                  <FormatPrice
                    formatted
                    price={
                      itemCount > 1
                        ? formatPrice(totalCostNumber)
                        : formattedTotalWithDiscounts
                    }
                    currency={currency?.symbol}
                  />
                )}
                {free ? t("Free") : cardCost}
              </span>
              {!isTablet && cardCostWithNoDiscount && !free && (
                <span
                  style={{ display: "inline-block" }}
                  className={clsx(classes?.price__discount)}
                >
                  <FormatPrice
                    formatted
                    price={
                      itemCount > 1
                        ? formatPrice(totalCostWithNoDiscountNumber)
                        : formattedTotal
                    }
                    currency={currency?.symbol}
                    text={itemCount > 1 ? "" : cardCostWithNoDiscount}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        {!isTablet && itemCount > 1 && cardCostWithText && !free && (
          <span
            className={clsx(
              classes.price__count,
              longCardCost && classes.price__longCount,
            )}
          >
            <span style={{ textAlign: "right" }}>
              <FormatPrice
                formatted
                price={formattedTotalWithDiscounts}
                currency={currency?.symbol}
                text={cardCostWithText}
              />
            </span>
          </span>
        )}
        <div className={classes.price__info}>
          {t("You’ll get the final price after consultation with a manager")}
        </div>
        <button className={classes.price__hint_btn} onClick={handleOpenModal}>
          <UiIcon name="HintIcon" additionalClassName={classes["hint-icon"]} />
        </button>
      </div>
      {isTablet &&
        (cardCostWithNoDiscount ? (
          <span className={classes?.price__discount}>
            <FormatPrice
              formatted
              price={
                itemCount > 1
                  ? formatPrice(totalCostWithNoDiscountNumber)
                  : formattedTotal
              }
              currency={currency?.symbol}
              text={itemCount > 1 ? "" : cardCostWithNoDiscount}
            />
          </span>
        ) : (
          itemCount > 1 &&
          cardCostWithText && (
            <span
              className={clsx(
                classes.price__count,
                longCardCost && classes.price__longCount,
              )}
            >
              <FormatPrice
                formatted
                price={formattedTotalWithDiscounts}
                currency={currency?.symbol}
                text={cardCostWithText}
              />
            </span>
          )
        ))}
    </div>
  );

  const handleShowButtons = (toShow: boolean) => {
    if (!isTablet) {
      setIsHovered(toShow);
    }
  };

  return (
    <div
      className={classes.item}
      onMouseOver={() => handleShowButtons(true)}
      onMouseOut={() => handleShowButtons(false)}
    >
      <div className={classes.left}>
        <Checkbox
          className={classes.checkbox}
          onChange={() => {
            setDisabled(true);
            cartStore.handleSelectedItem(item);
          }}
          checked={selectedItems.some(({ service }) => service.id === id)}
          disabled={disabled}
        />
        <div className={classes.leftBlock}>
          <div className={classes.box}>
            <div className={classes.tags}>
              {hotDeals.activeDeals && !hotDeals.featureDeals && deals && (
                <div className={clsx(classes.tag, classes["hit-tag"])}>
                  <div className={classes.hitIcon}>
                    <WhiteFireIcon />
                  </div>
                  <span className={classes.tagInner}>
                    {deals.cost_change_type === "MONEY" && (
                      <FormatPrice currency={currency?.symbol} withMinus />
                    )}
                    {hotDealDiscountText}
                  </span>
                </div>
              )}
              {isNew && (
                <div className={clsx(classes.tag, classes.new)}>{t("NEW")}</div>
              )}
              {free && (
                <div className={clsx(classes.tag, classes.free)}>
                  {t("FREE")}
                </div>
              )}
              <div className={classes.tag}>{productName}</div>
            </div>

            <Link to={`/solutions/${slug}`} className={classes.link}>
              {name}
            </Link>
            {hotDeals.activeDeals && !hotDeals.featureDeals && deals && (
              <div>
                <div className={classes.tooltipWrap}>
                  <span className={classes.closeTimeInner}>
                    {t("Closing time")}:{" "}
                    <span className={classes.closeTime}>
                      {dayEndDeal} {monthEndDeal} {yearEndDeal}{" "}
                    </span>
                  </span>
                  <div className={classes.closeTimeTooltip}>
                    <TooltipContainer
                      text={t(
                        "Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled",
                      )}
                      position={"top"}
                      className={classes.tooltipContainer}
                      classNameTooltip={classes.tooltip}
                    >
                      <InfoIcon
                        fill="rgba(137, 137, 137, .6)"
                        width="18px"
                        height="18px"
                      />
                    </TooltipContainer>
                  </div>
                </div>
              </div>
            )}
            {hotDeals.activeDeals && !hotDeals.featureDeals && deals && (
              <div>
                {hotDeals.activeDeals && (
                  <div className={classes.timer}>
                    <HotDealsCounter inner small />{" "}
                  </div>
                )}
              </div>
            )}
          </div>
          <Fader active={isDisabledButton}>
            <div className={classnames(classes.buttons)}>
              {isHovered && (
                <>
                  <Button
                    theme={"text"}
                    className={clsx(
                      classes.favButton,
                      isFavorite && classes.inFav,
                    )}
                    onClick={changeFavorites}
                    label={t("Add to favorites")}
                  >
                    {isFavorite ? (
                      <div className={classes.favButtonInner}>
                        <div
                          className={clsx(
                            classes.favIcon,
                            classes.favIconScale,
                          )}
                        >
                          <HeartBoldFilledIcon />
                        </div>
                      </div>
                    ) : (
                      <div className={classes.favButtonInner}>
                        <div className={classes.favIcon}>
                          <HeartBoldIcon />
                        </div>
                      </div>
                    )}
                  </Button>
                  <Button
                    theme={"text"}
                    className={classes.delete}
                    label={t("Delete solution")}
                    onClick={() => setOpenModal(true)}
                    disabled={isDisabledButton}
                  >
                    <div className={classes.deleteIcon}>
                      <TrashIcon width="21.6" height="21.6" />
                    </div>
                  </Button>
                </>
              )}
            </div>
          </Fader>
        </div>
      </div>
      <Fader active={isDisabledButton}>
        <div className={classnames(classes.right)}>
          <div className={classes.counter}>
            <button
              className={classes.counter__btn}
              disabled={isDisabledButton}
              onClick={removeFromCart}
            >
              <MinusIcon />
            </button>
            <input
              type="number"
              className={classes.counter__input}
              value={item.count}
              readOnly
            />
            <button
              className={classes.counter__btn}
              disabled={isDisabledButton}
              onClick={addToCart}
            >
              <PlusIcon />
            </button>
          </div>
          {renderPrice}
        </div>
      </Fader>

      <DeleteConfirmationModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onDelete={deleteFromCart}
        isDisabled={isDisabledButton}
        itemName={name}
      />

      <PriceInfoModal
        isOpen={openPriceModal}
        onClose={handleCloseModal}
        isTablet={isTablet}
        itemCount={itemCount}
        cardCostWithText={cardCostWithText}
        hasDiscount={hasDiscount}
        currency={currency?.symbol}
        modalContent={modalContent}
      />
    </div>
  );
};
