import classes from "./FormTopUpBalance.module.scss";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import { Input } from "components/Input";
import { userStore } from "stores/userStore";
import { formatBalanceForLocale } from "../formatBalanceForLocale";
import { RubIcon } from "components/Icons/RubIcon";
import { FormTopUpBalanceProps } from "./FormTopUpBalance.props";
import { useEffect, useLayoutEffect } from "react";
import { financesStore } from "stores/financesStore";
import Lottie from "lottie-react";
import loader from "../../../components/PageLoader/loader.json";
import { observer } from "mobx-react-lite";

export const FormTopUpBalance = observer(
  ({
    handleTopUpBalance,
    value,
    setValue,
    showTopUpModal,
  }: FormTopUpBalanceProps) => {
    const { loadConditions, conditions, isLoadingConditions } = financesStore;
    const feeService = conditions?.commission_prc_in;
    const { t } = useTranslation();
    const currency = userStore.user.currency?.char_code;
    const currentCurrencySymbol = userStore.user.currency?.symbol;

    useEffect(() => {
      if (showTopUpModal) loadConditions();
    }, [showTopUpModal]);

    const handleChangeValue = (e: any) => {
      const value = e.replace(/\s+/g, ""); // We remove spaces because in input we can have a division of "1000" into spaces "1 000"

      if (value === "") {
        setValue("");
      } else if (/^\d*$/.test(value)) {
        const numValue = Number(value);
        setValue(numValue.toString());
      }
    };

    if (isLoadingConditions) return <Lottie animationData={loader} />;

    const isDisabled = Number(value) <= 0;
    const totalValue =
      (Number(value) * Number(feeService)) / 100 + Number(value);
    const formattedTotalValue = formatBalanceForLocale(
      totalValue,
      currency,
      currentCurrencySymbol,
    );

    return (
      <div className={classes.inner}>
        <div className={classes.header}>
          <h3 className={classes.title}>{t("Top up balance")}</h3>
          <p className={classes["title-description"]}>
            {t("Replenishment is possible only with bank cards")}
          </p>
        </div>

        <div>
          <Input
            clearIcon={true}
            inputLabel={true}
            className={classes.input}
            iconJSX={<RubIcon />}
            onChange={handleChangeValue}
            type={"text"}
            placeholder={t("Value")}
            value={value.toString()}
            inputForCurrency={true}
          />
          <p className={classes["input-description"]}>
            {`${t("Commission for replenishment")} ${feeService}%${
              totalValue > 0 ? ` = ${formattedTotalValue}` : ""
            } `}
          </p>
        </div>

        <Button
          theme={"primary"}
          size="big"
          disabled={isDisabled}
          className={classes.btn}
          onClick={() => handleTopUpBalance(totalValue)}
        >
          {t("Top up")}
        </Button>
      </div>
    );
  },
);
