import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils";

export const PersonIcon = (props: SVGProps<SVGSVGElement>) => (
  <>
    {!RUSSIAN_LOCALE &&
      <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="25.5" fill="url(#paint0_linear_767_1139)" />
        <g clip-path="url(#clip0_767_1139)">
          <path d="M28.0477 29.5849C31.0396 30.4514 33.6674 32.2552 35.5374 34.7258C37.4074 37.1964 38.4189 40.2008 38.4202 43.2888L24.0016 58.4416L9.58301 43.2888C9.58365 40.2006 10.5948 37.1958 12.465 34.7251C14.3351 32.2544 16.9633 30.4508 19.9555 29.5849L24.0016 35.5974L28.0477 29.5849ZM31.2109 21.8627C31.2109 23.7569 30.4514 25.5735 29.0994 26.9129C27.7474 28.2523 25.9136 29.0047 24.0016 29.0047C22.0896 29.0047 20.2559 28.2523 18.9039 26.9129C17.5519 25.5735 16.7923 23.7569 16.7923 21.8627C16.7923 19.9685 17.5519 18.1519 18.9039 16.8126C20.2559 15.4732 22.0896 14.7207 24.0016 14.7207C25.9136 14.7207 27.7474 15.4732 29.0994 16.8126C30.4514 18.1519 31.2109 19.9685 31.2109 21.8627Z" fill="white" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_767_1139" x1="3" y1="-4.35859e-07" x2="42" y2="51.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#8362F3" />
            <stop offset="1" stop-color="#6943E8" />
          </linearGradient>
          <clipPath id="clip0_767_1139">
            <rect width="40" height="40" fill="white" transform="translate(4 11)" />
          </clipPath>
        </defs>
      </svg>
    }
    {!RUSSIAN_LOCALE &&
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_189_24952)" />
        <mask id="mask0_189_24952" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
          <circle cx="24" cy="24" r="24" fill="url(#paint1_linear_189_24952)" />
        </mask>
        <g mask="url(#mask0_189_24952)">
          <path d="M28.0477 29.5849C31.0396 30.4514 33.6674 32.2552 35.5374 34.7258C37.4074 37.1964 38.4189 40.2008 38.4202 43.2888L24.0016 58.4416L9.58301 43.2888C9.58365 40.2006 10.5948 37.1958 12.465 34.7251C14.3351 32.2544 16.9633 30.4508 19.9555 29.5849L24.0016 35.5974L28.0477 29.5849ZM31.2109 21.8627C31.2109 23.7569 30.4514 25.5735 29.0994 26.9129C27.7474 28.2523 25.9136 29.0047 24.0016 29.0047C22.0896 29.0047 20.2559 28.2523 18.9039 26.9129C17.5519 25.5735 16.7923 23.7569 16.7923 21.8627C16.7923 19.9685 17.5519 18.1519 18.9039 16.8126C20.2559 15.4732 22.0896 14.7207 24.0016 14.7207C25.9136 14.7207 27.7474 15.4732 29.0994 16.8126C30.4514 18.1519 31.2109 19.9685 31.2109 21.8627Z" fill="white" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_189_24952" x1="-23.28" y1="-26.64" x2="29.04" y2="31.2" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFB3D2" />
            <stop offset="1" stop-color="#FF4848" />
          </linearGradient>
          <linearGradient id="paint1_linear_189_24952" x1="3" y1="-4.35859e-07" x2="42" y2="51.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#8362F3" />
            <stop offset="1" stop-color="#6943E8" />
          </linearGradient>
        </defs>
      </svg>
    }
  </>
)
