import React, { FC } from "react";
import classes from "./LinksPolitic.module.scss";
import { useTranslation } from "react-i18next";
import { defaultLanguage } from "i18n/i18n";
import { RUSSIAN_LOCALE } from "utils";

interface LinksPoliticProps {}

export const LinksPolitic: FC<LinksPoliticProps> = ({}) => {
  const { t, i18n } = useTranslation();

  return (
    <ul className={classes["politic-links"]}>
      {!RUSSIAN_LOCALE && (
        <li>
          <a
            href={`https://kit.global/${
              i18n.language !== defaultLanguage ? i18n.language + "/" : ""
            }terms/`}
            target="_blank"
            className={classes["politic-links__link"]}
            rel="noreferrer"
          >
            {t("Terms of service")}
          </a>
        </li>
      )}
      <li>
        {RUSSIAN_LOCALE ? (
          <a
            href={`https://kokocgroup.ru/privacy/`}
            target="_blank"
            className={classes["politic-links__link"]}
            rel="noreferrer"
          >
            {t("Privacy Policy (single)")}
          </a>
        ) : (
          <a
            href={`https://kit.global/${
              i18n.language !== defaultLanguage ? i18n.language + "/" : ""
            }privacy-policy/`}
            target="_blank"
            className={classes["politic-links__link"]}
            rel="noreferrer"
          >
            {t("Privacy Policy (single)")}
          </a>
        )}
      </li>
      <li>
        <a
          href={
            RUSSIAN_LOCALE
              ? process.env.REACT_APP_RU_COOKIES_CONCENT_URL || ""
              : `https://kit.global/${
                  i18n.language !== defaultLanguage ? i18n.language + "/" : ""
                }cookie-policy/`
          }
          target="_blank"
          className={classes["politic-links__link"]}
          rel="noreferrer"
        >
          {t("Cookie Policy_footer")}
        </a>
      </li>
    </ul>
  );
};
