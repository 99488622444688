import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { RocketIcon } from "components/Icons/RocketIcon";
import { SearchIcon } from "components/Icons/SearchIcon";
import { ServicesIcon } from "components/Icons/ServicesIcon";
import { WalletIcon } from "components/Icons/WalletIcon";
import { Input } from "components/Input";
import classes from "./MobileFilters.module.scss";
import { CrossIcon } from "components/Icons/CrossIcon";
import { ResetIcon } from "components/Icons/ResetIcon";
import { Modal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { userStore } from "stores/userStore";
import { marketPlaceStore } from "stores/marketPlaceStore";
import i18n from "i18n/i18n";
import { handleFilterServices } from "containers/CardsContainer/utils";
import {
  FilterProps,
  ServiceFilterProps,
} from "containers/CardsContainer/types";
import { declOfNum } from "tools/utils";

export const MobileFilters: FC<ServiceFilterProps> = ({
  serviceTypesChecks,
  setServiceTypes,
  businessTypesChecks,
  setBusinessTypes,
  maxCost,
  budget,
  setBudget,
  isOpen,
  onClose,
  count,
  serviceTypesOf,
  businessTypesOf,
  setCurrentPage,
  pageSize,
  setProductsToShow,
  topFilters,
  isFavorites,
  sortSelect,
  priceMin,
  setClearBusinessTypes,
  setMoreButtonClicked,
  setPriceMin,
  setIsFirstRender,
}) => {
  const [serviceOptions, setServiceOptions] = useState(serviceTypesOf);
  const [serviceSearch, setServiceSearch] = useState("");
  const [businessOptions, setBusinessOptions] = useState(businessTypesOf);
  const [businessSearch, setBusinessSearch] = useState("");
  const [showServices, setShowServices] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);
  const { t } = useTranslation();

  // Фильтр по стоимости
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);

  const user = userStore.user;

  useEffect(() => {
    if (budget) {
      //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
      budget === -1 ? setMaxValue(0) : setMaxValue(budget);
    } else if (budget === 0 || maxCost) {
      setMaxValue(maxCost);
    } else {
      if (budget) {
        //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
        budget === -1 ? setMaxValue(0) : setMaxValue(budget);
      }
    }
  }, [maxCost]);

  useEffect(() => {
    if (priceMin) {
      setMinValue(priceMin);
    } else {
      setMinValue(0);
    }
  }, [priceMin]);

  const handleChangeMinValue = (e: any) => {
    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      if (numValue <= maxCost) {
        setMinValue(numValue);

        if (budget === 0) setMaxValue(maxCost);
      }
    }
  };

  const handleChangeMaxValue = (e: any) => {
    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      setMaxValue(numValue)
    }
  };

  const handleChangeMinPrice = (e: any) => {
    if (e.includes("e") || e.includes("+")) {
      setMaxValue(maxCost);
      return;
    }

    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    // Проверка на ввод только цифр
    if (/^\d*$/.test(value)) {
      const numValue = Number(value);
      const limit = budget !== null && budget > 0 ? budget : maxCost;

      if (numValue === priceMin) return;

      if (numValue <= limit) {
        setMoreButtonClicked(false);
        setPriceMin(numValue);

        if (budget === 0) setBudget(maxCost);

        handleFilter({ price_max: budget, price_min: priceMin });
      } else {
        setMinValue(priceMin);
      }
    }
  };

  const handleChangeMaxPrice = (e: any) => {
    if (e.includes("e") || e.includes("+")) {
      setMaxValue(maxCost);
      return;
    }

    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    // Проверка на ввод только цифр
    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      if (
        (budget === 0 && numValue === maxCost) ||
        (budget && budget > maxCost && numValue !== 0 && numValue === maxCost)
      )
        return;

      if (numValue <= maxCost && numValue >= priceMin) {
        setMoreButtonClicked(false);
        //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
        numValue === 0 ? setBudget(-1) : setBudget(numValue);

        handleFilter({ price_max: budget, price_min: priceMin });
      } else if (numValue > maxCost) {
        setMoreButtonClicked(false);
        setBudget(maxCost);
        setMaxValue(maxCost);
        handleFilter({ price_max: maxCost, price_min: priceMin });
      } else {
        if (budget) setMaxValue(budget);
      }
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: (value: string) => void,
  ) => {
    if (event.key === "Enter") {
      callback((event.target as HTMLInputElement).value);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    callback: (value: string) => void,
  ) => {
    callback((event.target as HTMLInputElement).value);
  };
  // _________

  useEffect(() => {
    if (serviceSearch !== "") {
      let newOptions = serviceTypesOf.filter((item: any) => {
        if (item.name.toLowerCase().includes(serviceSearch.toLowerCase())) {
          return item;
        }
      });
      setServiceOptions(newOptions);
      return;
    }
    setServiceOptions(serviceTypesOf);
  }, [serviceSearch, serviceTypesOf]);

  useEffect(() => {
    if (businessSearch !== "") {
      let newOptions = businessTypesOf.filter((item: any) => {
        if (item.name.toLowerCase().includes(businessSearch.toLowerCase())) {
          return item;
        }
      });

      setBusinessOptions(newOptions);
      return;
    }

    // Если у пользвателя указан business type - тогда мы выводим в списке фильтров его на первое место.
    if (user.company_industry && !isFavorites) {
      const indexUserCompanyIndustry = businessTypesOf.findIndex((item:any) => item.id === user.company_industry)

      if (indexUserCompanyIndustry > -1) {
        const newOptions = businessTypesOf;
        const [businessType] = businessTypesOf.splice(
          indexUserCompanyIndustry,
          1,
        );
        newOptions.unshift(businessType);

        if(!businessTypesChecks.find(item => item === businessType.id)) {
          setBusinessTypes([...businessTypesChecks, businessType.id])
        }

        setBusinessOptions(newOptions);
      } 
      setIsFirstRender(false)
    } else {
      setBusinessOptions(businessTypesOf);
      setIsFirstRender(false);
    }
  }, [businessSearch, businessTypesOf]);

  const resetSearchService = () => {
    setServiceTypes([]);
    setServiceSearch("");
    handleFilter({ serviceTypes: [] });
  };

  const resetSearchBusiness = () => {
    setBusinessTypes([]);
    setBusinessSearch("");
    handleFilter({ businessTypes: [] });
  };

  const resetAll = () => {
    setServiceTypes([]);
    setServiceSearch("");

    setClearBusinessTypes(true);
    setBusinessTypes([]);
    setBusinessSearch("");

    setBudget(0);
    setPriceMin(0);
    setMaxValue(maxCost);
    setMinValue(0);
  };

  const sortParams = () => {
    switch (sortSelect?.id) {
      case 1:
        return "popular";
      case 2:
        return "cost_lth";
      case 3:
        return "cost_htl";
    }
  };

  const order = sortParams();

  const handleFilter = (options: FilterProps) => {
    handleFilterServices(
      options,
      pageSize,
      businessTypesChecks,
      serviceTypesChecks,
      setProductsToShow,
      topFilters,
      isFavorites,
      order,
    );
  };

  const handleClearPrice = () => {
    setCurrentPage(1);
    setMoreButtonClicked(false);
    setPriceMin(0);
    setBudget(0);
    setMaxValue(maxCost)
  };

  return (
    <div className={clsx(classes.wrapper)}>
      <div className={classes.filter}>
        <div className={classes.title}>
          <span>
            <ServicesIcon /> {t("Type of solutions")}
            {serviceTypesChecks?.length > 0 && (
              <Button
                onClick={() => resetSearchService()}
                className={classes.clear}
                theme="icon-default"
              >
                {serviceTypesChecks?.length}
                <CrossIcon />
              </Button>
            )}
          </span>
          <Button
            onClick={() => setShowServices(true)}
            className={classes.button}
            theme="text"
          >
            {t("Show all")}
          </Button>
        </div>
        <div className={classes.choices}>
          {serviceOptions.slice(0, 5).map((item: any) => (
            <Checkbox
              key={item.id}
              className={classes.check}
              onChange={() => {
                setCurrentPage(1);
                if (serviceTypesChecks.includes(item.id)) {
                  let arr = serviceTypesChecks.filter(
                    (val: any) => val !== item.id,
                  );
                  setServiceTypes(arr);
                  handleFilter({ serviceTypes: arr });
                  return;
                }
                let arr = [...serviceTypesChecks, item.id];
                setServiceTypes(arr);
                handleFilter({ serviceTypes: arr });
              }}
              checked={serviceTypesChecks.includes(item.id)}
              label={item[`name_${i18n.language}`] ?? item.name}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>
        <div className={classes.emptyButton} />
      </div>
      <div className={classes.filter}>
        <div className={classes.title}>
          <span>
            <RocketIcon className={classes.rocket} /> {t("Type of business")}
            {businessTypesChecks?.length > 0 && (
              <Button
                onClick={() => resetSearchBusiness()}
                className={classes.clear}
                theme="icon-default"
              >
                {businessTypesChecks?.length}
                <CrossIcon />
              </Button>
            )}
          </span>
          <Button
            onClick={() => setShowBusiness(true)}
            className={classes.button}
            theme="text"
          >
            {t("Show all")}
          </Button>
        </div>
        <div className={classes.choices}>
          {businessOptions.slice(0, 5).map((item: any) => (
            <Checkbox
              key={item.id}
              className={classes.check}
              onChange={() => {
                if (businessTypesChecks.includes(item.id)) {
                  let arr = businessTypesChecks.filter(
                    (val: any) => val !== item.id,
                  );

                  setClearBusinessTypes(true);
                  setBusinessTypes(arr);
                  handleFilter({ businessTypes: arr });
                  return;
                }
                let arr = [...businessTypesChecks, item.id];
                setBusinessTypes(arr);
                handleFilter({ businessTypes: arr });
              }}
              checked={businessTypesChecks.includes(item.id)}
              label={item[`name_${i18n.language}`] ?? item.name}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>
        <div className={classes.emptyButton} />
      </div>
      <div className={classes.filter}>
        <div className={classes.title}>
          <span>
            <WalletIcon /> {t("Price")}
          </span>
          {(priceMin !== 0 || budget !== 0) && (
            <Button
              onClick={() => handleClearPrice()}
              // className={classes.clearPrice}
              className={classes.clear}
              theme="icon-default"
            >
              {t("Clear all")}
              <CrossIcon />
            </Button>
          )}
        </div>

        <div className={classes["choices-budget"]}>
          <Input
            onKeyDown={e => handleKeyDown(e, handleChangeMinPrice)}
            onBlur={e => handleBlur(e, handleChangeMinPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMinValue}
            type={"text"}
            placeholder={t("Min price")}
            value={minValue.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
          <Input
            onKeyDown={e => handleKeyDown(e, handleChangeMaxPrice)}
            onBlur={e => handleBlur(e, handleChangeMaxPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMaxValue}
            labelPosition={"left"}
            type={"text"}
            placeholder={t("Max price")}
            value={maxValue?.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
        </div>
        <div className={classes.emptyButton} />
      </div>
      {showServices && (
        <Modal
          isOpen={showServices}
          onClose={() => setShowServices(false)}
          subModal={true}
          title={t("Types of solutions")}
          className={classes.modalFilter}
          classNameForContent={classes.modalMobileFilters}
        >
          <div className={classes.filter}>
            <div className={classes["search-field"]}>
              <SearchIcon />
              <Input
                value={serviceSearch}
                onChange={setServiceSearch}
                placeholder={t("Search")}
                className={classes.search}
              />
            </div>
            <div className={classes.count}>
              <div className={classes.text}>
                {t("Selected")}: {serviceTypesChecks.length}
              </div>
              <Button
                className={classes.clear}
                onClick={() => {
                  resetSearchService();
                }}
                theme="text"
              >
                {t("Clear all")}
              </Button>
            </div>
            <div className={classes.choices}>
              {serviceOptions.map((item: any) => (
                <Checkbox
                  key={item.id}
                  className={classes.check}
                  onChange={() => {
                    setCurrentPage(1);
                    if (serviceTypesChecks.includes(item.id)) {
                      let arr = serviceTypesChecks.filter(
                        (val: any) => val !== item.id,
                      );
                      setServiceTypes(arr);
                      handleFilter({ serviceTypes: arr });
                      return;
                    }
                    let arr = [...serviceTypesChecks, item.id];
                    setServiceTypes(arr);
                    handleFilter({ serviceTypes: arr });
                  }}
                  checked={serviceTypesChecks.includes(item.id)}
                  label={item[`name_${i18n.language}`] ?? item.name}
                  disabled={marketPlaceStore.isLoadingServicePages}
                />
              ))}
            </div>
            <div className={classes.emptyButton} />
            <div className={clsx(classes["bottom-buttons"], classes.open, classes["bottom-buttons-apply"])}>
              <Button
                onClick={() => {
                  setServiceTypes([...serviceTypesChecks]);
                  setShowServices(false);
                  setServiceSearch("");
                }}
                className={clsx(classes.button, classes.apply)}
                theme="primary"
              >
                {t("Apply")}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showBusiness && (
        <Modal
          isOpen={showBusiness}
          onClose={() => setShowBusiness(false)}
          subModal={true}
          title={t("Type of business")}
          className={classes.modalFilter}
          classNameForContent={classes.modalMobileFilters}
        >
          <div className={classes.filter}>
            <div className={classes["search-field"]}>
              <SearchIcon />
              <Input
                value={businessSearch}
                onChange={setBusinessSearch}
                placeholder={t("Search")}
                className={classes.search}
              />
            </div>
            <div className={classes.count}>
              <div className={classes.text}>
                {t("Selected")}: {businessTypesChecks.length}
              </div>
              <Button
                className={classes.clear}
                onClick={() => resetSearchBusiness()}
                theme="text"
              >
                {t("Clear all")}
              </Button>
            </div>
            <div className={classes.choices}>
              {businessOptions.map((item: any) => (
                <Checkbox
                  className={classes.check}
                  onChange={() => {
                    if (businessTypesChecks.includes(item.id)) {
                      let arr = businessTypesChecks.filter(
                        (val: any) => val !== item.id,
                      );

                      setClearBusinessTypes(true);
                      setBusinessTypes(arr);
                      handleFilter({ businessTypes: arr });
                      return;
                    }
                    let arr = [...businessTypesChecks, item.id];
                    setBusinessTypes(arr);
                    handleFilter({ businessTypes: arr });
                  }}
                  checked={businessTypesChecks.includes(item.id)}
                  label={item[`name_${i18n.language}`] ?? item.name}
                  disabled={marketPlaceStore.isLoadingServicePages}
                />
              ))}
            </div>
          </div>
          <div className={classes.emptyButton} />
          <div className={clsx(classes["bottom-buttons"], classes.open, classes["bottom-buttons-apply"])}>
            <Button
              onClick={() => {
                setBusinessTypes([...businessTypesChecks]);
                setShowBusiness(false);
                setBusinessSearch("");
              }}
              className={clsx(classes.button, classes.apply)}
              theme="primary"
            >
              {t("Apply")}
            </Button>
          </div>
        </Modal>
      )}
      <div className={classes.emptyButton} />
      <div className={clsx(classes["bottom-buttons"], isOpen && classes.open)}>
        <Button
          className={clsx(classes.button, classes.reset)}
          theme="text"
          onClick={() => resetAll()}
        >
          {t("Reset")} <ResetIcon />
        </Button>
        <Button onClick={onClose} className={classes.button} theme="primary">
          {`${t("Show")} ${count} ${declOfNum(count || 0, [
            t("solution"),
            t("solutions"),
            t("of solutions"),
          ])}`}
        </Button>
      </div>
    </div>
  );
};
