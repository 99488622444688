import React, {FC} from 'react';
import { RUSSIAN_LOCALE } from '../../utils';
interface FormatPriceProps {
	currency: string;
	price?: number | string;
	withMinus?: boolean;
	text?: string;
	formatted?: boolean;
}

export const formatPrice = (price: number | string) => {
	const realPrice: number = Number(price) < 1 && Number(price) > 0 ? Math.ceil(Number(price)) : Math.floor(Number(price));
	const parts = realPrice.toString()?.split('.');

	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts[0]
}

const FormatPrice: FC<FormatPriceProps> = ({ currency, price, withMinus, text, formatted=false }) => {

	const hasRupeeSymbol = /₹/.test(currency);

	const renderText = () => {
		const test = hasRupeeSymbol ? { fontFamily: 'Noto, sans-serif' } : { fontFamily: 'inherit' }
		const space = RUSSIAN_LOCALE ? '\u00A0' : '';
		const formattedPrice = formatted ? price : price && formatPrice(price)
		return (
			<span>
				{RUSSIAN_LOCALE ? (
				<>
				{formattedPrice}<span style={test}>{space}{withMinus ? `-${currency}` : currency}</span>
				</>
			) : (
				<>
				<span style={test}>{withMinus ? `-${currency}` : currency}</span>{formattedPrice}
				</>
			)}
			{text && text}
			</span>
		);
	};

	return (
		<>
			{renderText()}
		</>
	);
};

export default FormatPrice;