import classes from "./FormPaymentBill.module.scss";
import { Button } from "components/Button";
import { FormTopUpBalanceProps } from "./FormPaymentBill.props";
import { observer } from "mobx-react-lite";
import { formatBalanceForLocale } from "../formatBalanceForLocale";
import { Bill } from "types/finances";
import { useFormPaymentBill } from "./useFormPaymentBill";
import { PaymentSuccessIcon } from "components/Icons/PaymentSuccessIcon";
import { PaymentFailIcon } from "components/Icons/PaymentFailIcon";

export const FormPaymentBill = observer(
  ({ onClose, handleShowTopUpModal,showPaymentBillModal }: FormTopUpBalanceProps) => {
    const {
      balance,
      currency,
      currentCurrencySymbol,
      t,
      isTopUpBalance,
      isLoading,
      handlePayment,
      handleTopUpBalance,
      totalAmount,
      bills,
      isSuccess,
      handleClose
    } = useFormPaymentBill(onClose, handleShowTopUpModal, showPaymentBillModal);

    return (
      <div className={classes.inner}>
        {isSuccess.alreadyTryToPay ? (
          <div className={classes.success}>
            {isSuccess.success ? <PaymentSuccessIcon /> : <PaymentFailIcon />}

            <div>
              {isSuccess.success ? (
                <p className={classes["success-title"]}>
                  {t("The bill has been paid")}
                </p>
              ) : (
                <p className={classes["success-title"]}>
                  {t("The bill is not paid")}
                </p>
              )}

              {isSuccess.success ? (
                <p className={classes["success-description"]}>
                  {t("Thank you for your trust")}
                </p>
              ) : (
                <p className={classes["success-description"]}>
                  {t(
                    "We are already fixing it, try making a payment in a few minutes",
                  )}
                </p>
              )}
            </div>

            <Button
              theme={"primary"}
              size="middle"
              disabled={isLoading}
              className={classes.btn}
              onClick={handleClose}
            >
              {t("Good")}
            </Button>
          </div>
        ) : (
          <>
            <div className={classes.header}>
              <h3 className={classes.title}>{t("Payment of the bill")}</h3>
            </div>

            <div className={classes.content}>
              <p className={classes["balance-title"]}>{t("Balance")}</p>
              <p className={classes["balance-value"]}>
                {formatBalanceForLocale(balance, currency, currentCurrencySymbol)}
              </p>
              {isTopUpBalance && (
                <p className={classes["isTopUpBalance"]}>
                  {t(
                    "There are not enough funds on your balance. Please top up your balance to make a successful payment",
                  )}
                </p>
              )}

              {isTopUpBalance ? (
                <Button
                  theme={"light"}
                  size="middle"
                  disabled={isLoading}
                  className={classes.btn}
                  onClick={handleTopUpBalance}
                >
                  {t("Top up")}
                </Button>
              ) : (
                <Button
                  theme={"light"}
                  size="middle"
                  disabled={isLoading}
                  className={classes.btn}
                  onClick={handlePayment}
                >
                  {t("Pay now")} {formatBalanceForLocale(totalAmount, currency, currentCurrencySymbol)}
                </Button>
              )}
            </div>

            <ul className={classes.list}>
              {bills.map((b: Bill) => (
                <li key={b.serial_id} className={classes["item"]}>
                  <ul className={classes["item-content"]}>
                    <li className={classes["item-name"]}>{b.project_name}</li>
                    <li className={classes["item-id"]}>
                      ID {b.project_number}
                    </li>
                  </ul>
                  <p className={classes["item-amount"]}>
                    {formatBalanceForLocale(b.amount, currency, currentCurrencySymbol)}
                  </p>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  },
);
