import { FC, Suspense } from "react";
import { useLoaderData, Await } from "react-router-dom";
import CardsContainerSkeleton from "./Skeleton/CardsContainerSkeleton/CardsContainerSkeleton";

export interface Props {
  children?: any;
  title: string;
}

export const LoaderWrapperCardsContainer: FC<Props> = ({ title, children }) => {
  const loadedData: any = useLoaderData();

  return (
    <Suspense fallback={<CardsContainerSkeleton title={title} />}>
      <Await resolve={loadedData?.loader}>{children}</Await>
    </Suspense>
  );
};
