import React, { FC } from 'react';
import '../styles/main.scss';
import { useTranslation } from 'react-i18next';
import { CardsContainer } from 'containers/CardsContainer';

// todo add localization feature
export const Favorites: FC = () => {
  const { t } = useTranslation();

  return <CardsContainer title={t('Favorites')} />;
};
