import { FC, useEffect, useState } from "react";
import classes from "./Header.module.scss";
import { useCookies } from "react-cookie";
import { useWindowWidth } from "hooks/useWindowWidth";
import { NavLink, useLocation } from "react-router-dom";
import { Logo } from "components/Logo";
import { Menu } from "components/Menu";
import { User } from "components/User";
import { ProjectIcon } from "components/Icons/ProjectIcon";
import { HeartGradienIcon } from "components/Icons/HeartGradienIcon";
import { BasketGradientIcon } from "components/Icons/BasketGradientIcon";
import { GlobusGradientIcon } from "components/Icons/GlobusGradientIcon";
import { Button } from "components/Button";
import { MenuGradientIcon } from "components/Icons/MenuGradientIcon";
import { CrossIcon } from "components/Icons/CrossIcon";
import { Modal } from "components/Modal";
import { LinksPolitic } from "components/LinksPolitic";
import { LocalPreferencesForm } from "./LocalPreferences";
import { routers } from "constants/routers";
import { languages } from "constants/languages";

import clsx from "clsx";
import { LogoutGradientIcon } from "components/Icons/LogoutGradientIcon";
import { AdminGradientIcon } from "components/Icons/AdminGradientIcon";
import { AdminGradientIconActive } from "components/Icons/AdminGradientIconActive";
import { MarketplaceIcon } from "components/Icons/MarketplaceIcon";
import { ShapeGradientIcon } from "components/Icons/ShapeGradientIcon";
import { toJS } from "mobx";
import { useCartStore } from "contexts/CartContext";
import { useAuthStore } from "contexts/AuthContext";
import { useUserStore } from "contexts/UserContext";
import { observer } from "mobx-react-lite";
import { useOpenMenu } from "hooks/useOpenMenu";
import { GetCountries } from "api/getCountries";
import { GetCurrencies } from "api/getCurrencies";
import { ChangeUser } from "api/changeUser";
import { ShowNotification } from "tools/showNotification";
import { useMarketPlaceStore } from "contexts/marketPlaceContext";
import { ReloadStores } from "tools/reloadStores";
import { useTranslation } from "react-i18next";
import { SearchComponent } from "components/SearchComponent";
import { SearchGradientIcon } from "components/Icons/SearchGradientIcon";
import { RUSSIAN_LOCALE } from "../../utils";
import { casesStore } from "stores/casesStore";

interface Props {
  disabled?: boolean;
  allowLogout?: boolean;
}

export const Header: FC<Props> = observer(
  ({ disabled = false, allowLogout }) => {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { currency, country } = userStore?.user || {};
    const [cookies, setCookies] = useCookies(["access", "refresh"]);
    const marketPlaceStore = useMarketPlaceStore();
    const favoriteCount = toJS(marketPlaceStore).favoriteCount;

    console.log(toJS(userStore.user), "userStore");

    const isTestUser =
      userStore?.user?.id === "demo@platform.kokoc" ||
      userStore?.user?.id === 753 ||
      userStore.user.email === "demo@platform.kokoc" ||
      userStore.user.email === "shy.elva@yandex.ru " 

    const [active, setActive] = useState(false);
    const cartStore = useCartStore();

    const [isAdmin, setIsAdmin] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [userCountry, setUserCountry] = useState<any>(country);
    const [userCurrency, setUserCurrency] = useState<any>(currency);
    const [countries, setCountries] = useState<any>([]);
    const [currencies, setCurrencies] = useState<any>([]);
    const [isOpenLocalPreferencesModal, setIsOpenLocalPreferencesModal] =
      useState(false);
    const [isSuccessLocalPreferences, setIsSuccessLocalPreferences] =
      useState(false);
    const [isDisabledLocalPreferences, setIsDisabledLocalPreferences] =
      useState(false);

    const { i18n, t } = useTranslation();

    const [path, setPath] = useState("");
    const [userName, setUserName] = useState("");
    const [openSearch, setOpenSearch] = useState(false);
    const [bottomMenuSize, setBottomMenuSize] = useState(56);

    const { isMediaTablet, width } = useWindowWidth();

    const deals = cartStore.deals || [];
    const countCart = cartStore.cart ? cartStore.cart.services_count : 0;
    const modalOpen = cartStore.modalOpen || casesStore.isOpened;

    useEffect(() => {
      isMediaTablet ? setPath(routers.account) : setPath(routers.personal);
    }, [isMediaTablet]);

    useEffect(() => {
      if (cartStore?.fixedButtonSize && isMediaTablet) {
        setBottomMenuSize(cartStore?.fixedButtonSize);
      }
    }, [cartStore?.fixedButtonSize]);

    const openMenu = () => {
      setActive(true);
    };

    const closeMenu = () => {
      setActive(false);
    };

    useOpenMenu(active);
    useOpenMenu(openSearch);

    const getParams = () => {
      if (userStore.user) {
        setUserName(userStore.user.first_name);
        setIsAdmin(userStore.user.is_superuser);
        setIsManager(userStore.user.is_staff);
        setUserCountry(userStore.user.country);
        setUserCurrency(userStore.user.currency);
      }
    };

    useEffect(() => {
      if (authStore.isAuth) {
        getParams();
      }
    }, [userStore.user]);

    let isActiveLink = false;
    const location = useLocation();

    const routersMatch = [
      routers.account,
      routers.personal,
      routers.business,
      routers.changePassword,
    ];

    routersMatch.includes(location.pathname)
      ? (isActiveLink = true)
      : (isActiveLink = false);

    const clearStore = () => {
      cartStore.clearStore();
      userStore.clearStore();
      marketPlaceStore.clearStore();
    };

    const resetCookies = async (e: any) => {
      e.preventDefault();
      clearStore();
      console.log("haha");
      await authStore.logout();
    };

    const handleOpenLocalPreferencesModal = () => {
      getCountries();
      getCurrencies();
      setIsOpenLocalPreferencesModal(true);
    };

    const handleCloseLocalPreferencesModal = () => {
      setIsOpenLocalPreferencesModal(false);
    };

    const getCountries = async () => {
      try {
        const response = await GetCountries({
          access: cookies.access,
          refresh: cookies.refresh,
        });
        setCountries(response);
      } catch (error) {
        setCountries([]);
      }
    };

    const getCurrencies = async () => {
      try {
        const response = await GetCurrencies({
          access: cookies.access,
          refresh: cookies.refresh,
        });
        setCurrencies(response);
      } catch (error) {
        setCurrencies([]);
      }
    };

    useEffect(() => {
      if (RUSSIAN_LOCALE) setIsSuccessLocalPreferences(true);
      if (userStore.user.country && userStore.user.currency) {
        setIsSuccessLocalPreferences(true);
      }
    }, [userStore.user]);

    const handleSendLocalPreferences = async (data: any) => {
      setIsDisabledLocalPreferences(true);
      try {
        const response = await ChangeUser({
          access: cookies.access,
          data: data,
        });
        userStore.addUser(response.user);
        setUserCountry(response.user.country);
        setUserCurrency(response.user.currency);
        setIsSuccessLocalPreferences(true);
        ReloadStores();
        userStore.setLocaleChanged(true);
        i18n.changeLanguage(response.user.language.lang_code, (error, t) => {
          if (error) {
            ShowNotification({
              type: "error",
              children: t("An error occurred. Please try again later"),
            });
          } else {
            ShowNotification({
              type: "success",
              children: t("Changes saved successfully"),
            });
          }
        });
        setIsOpenLocalPreferencesModal(false);
      } catch (error) {
        console.error(error);
        ShowNotification({
          type: "error",
          children: t("An error occurred. Please try again later"),
        });
      }
      setIsDisabledLocalPreferences(false);
    };
    const disableHeader =
      !isSuccessLocalPreferences || !userStore.user.email_confirmed;

    const headerButtons = width <= 1024 && !active && (
      <div
        style={{
          // zIndex: modalOpen ? 102 : cartStore.zIndex,
          zIndex: 102,
          height: bottomMenuSize,
        }}
        className={clsx(classes["header__bottom-menu"], classes["bottom-menu"])}
      >
        <ul className={clsx(classes["bottom-menu__list"])}>
          <li>
            <NavLink
              to={routers.solutions}
              className={navData =>
                clsx(
                  classes["bottom-menu__item"],
                  classes.linkWrap,
                  navData.isActive && classes.active,
                  navData.isActive && RUSSIAN_LOCALE && classes.active_ru,
                  disableHeader && classes.disabled,
                )
              }
            >
              <MarketplaceIcon />
              <div className={classes["bottom-menu__item-text"]}>
                {t("Solutions")}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={routers.favorites}
              className={navData => {
                return clsx(
                  classes["bottom-menu__item"],
                  navData.isActive && classes.active,
                  navData.isActive && RUSSIAN_LOCALE && classes.active_ru,
                  disableHeader && classes.disabled,
                );
              }}
            >
              <div className={classes["header__counter"]}>
                <HeartGradienIcon />
                {favoriteCount !== undefined && favoriteCount !== 0 && (
                  <div className={classes["header__counter-count"]}>
                    <span>{favoriteCount}</span>
                  </div>
                )}
              </div>
              <div className={classes["bottom-menu__item-text"]}>
                {t("Favorites")}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={routers.cart}
              className={navData =>
                clsx(
                  classes["bottom-menu__item"],
                  navData.isActive && classes.active,
                  navData.isActive && RUSSIAN_LOCALE && classes.active_ru,
                  disableHeader && classes.disabled,
                )
              }
            >
              <div className={classes["header__counter"]}>
                <BasketGradientIcon />
                {countCart !== 0 && countCart !== undefined && (
                  <div className={classes["header__counter-count"]}>
                    <span>{countCart}</span>
                  </div>
                )}
              </div>
              <div className={classes["bottom-menu__item-text"]}>
                {t("Cart")}
              </div>
            </NavLink>
          </li>
          {width > 1024 && (
            <li>
              <NavLink
                to={routers.projects}
                className={navData =>
                  clsx(
                    classes["bottom-menu__item"],
                    navData.isActive && classes.active,
                    disableHeader && classes.disabled,
                  )
                }
              >
                <div className={classes["header__counter"]}>
                  <ProjectIcon />
                  {deals?.length > 0 && (
                    <div className={classes["header__counter-count"]}>
                      <span>{deals?.length}</span>
                    </div>
                  )}
                </div>
                <div className={classes["bottom-menu__item-text"]}>
                  {t("Projects")}
                </div>
              </NavLink>
            </li>
          )}
          <li
            className={clsx(disableHeader && !allowLogout && classes.disabled)}
          >
            <NavLink
              to={path}
              className={clsx(
                classes["bottom-menu__item"],
                classes["bottom-menu__item--user"],
                isActiveLink && classes.active,
                isActiveLink && RUSSIAN_LOCALE && classes.active_ru,
                (!isSuccessLocalPreferences && !allowLogout) ||
                  (!userStore.user.email_confirmed && classes.disabled),
              )}
            >
              <User name={userName} />
            </NavLink>
          </li>
        </ul>
      </div>
    );

    const goToKokocID = () => {
      window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`;
    };

    return (
      <>
        <header
          className={clsx(classes["header"], active && classes["active"])}
        >
          <div className={classes["header__inner"]}>
            <Logo />
            <div className={classes["header__activation"]}>
              <div
                className={classes["header__search-icon"]}
                onClick={() => !disableHeader && setOpenSearch(!openSearch)}
              >
                <SearchGradientIcon />
              </div>
              <div
                className={clsx(
                  classes["header__burger"],
                  disableHeader && classes.disabled,
                )}
              >
                {!active ? (
                  <Button
                    className={clsx(
                      disabled && !allowLogout && classes.disabled,
                    )}
                    onClick={openMenu}
                  >
                    <MenuGradientIcon />
                  </Button>
                ) : (
                  <Button onClick={closeMenu}>
                    <CrossIcon
                      width={"15px"}
                      height={"15px"}
                      fill={"#252529"}
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <nav className={classes["header__nav"]}>
            <Menu
              active={active}
              disabled={disableHeader}
              closeMenu={closeMenu}
            />
            <div className={classes["header__block"]}>
              <div className={classes["header__search"]}>
                {(openSearch || width > 1024) && (
                  <SearchComponent
                    disabled={disableHeader}
                    setOpenSearch={setOpenSearch}
                  />
                )}
              </div>
              <ul className={classes["header__list"]}>
                <li className={clsx(classes["header__list-item"])}>
                  <NavLink
                    to={routers.favorites}
                    className={navData => {
                      return clsx(
                        classes["header__list-link"],
                        navData.isActive && classes.active,
                        disableHeader && classes.disabled,
                      );
                    }}
                  >
                    <div className={classes["header__counter"]}>
                      <HeartGradienIcon />
                      {favoriteCount !== undefined && favoriteCount !== 0 && (
                        <div className={classes["header__counter-count"]}>
                          <span>{favoriteCount}</span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </li>
                <li className={clsx(classes["header__list-item"])}>
                  <NavLink
                    to={routers.cart}
                    className={navData =>
                      clsx(
                        classes["header__list-link"],
                        classes["cart"],
                        navData.isActive && classes.active,
                        disableHeader && classes.disabled,
                      )
                    }
                  >
                    <div className={classes["header__counter"]}>
                      <BasketGradientIcon />
                      {countCart !== 0 && countCart !== undefined && (
                        <div className={classes["header__counter-count"]}>
                          <span>{countCart}</span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </li>
                {!RUSSIAN_LOCALE && (
                  <li
                    className={clsx(
                      classes["header__list-item"],
                      classes["header__globus"],
                      disableHeader && classes.disabled,
                    )}
                  >
                    <div
                      className={clsx(classes["header__list-link"])}
                      onClick={handleOpenLocalPreferencesModal}
                    >
                      <GlobusGradientIcon />
                    </div>
                    <div className={classes.modal}>
                      <ul className={classes.modalListGlobus}>
                        <li className={classes.modalItem}>
                          <div className={classes.modalInnerColumn}>
                            <span className={classes.modalNameHighlight}>
                              {t("Currency") + ":"}
                            </span>
                            <span className={classes.modalName}>
                              {userStore.user &&
                                userStore.user?.currency?.char_code}
                            </span>
                          </div>
                        </li>
                        <li className={classes.modalItem}>
                          <div className={classes.modalInnerColumn}>
                            <span className={classes.modalNameHighlight}>
                              {t("Country") + ":"}
                            </span>
                            <span className={classes.modalName}>
                              {userCountry && userCountry?.name}
                            </span>
                          </div>
                        </li>
                        <li className={classes.modalItem}>
                          <div className={classes.modalInnerColumn}>
                            <span className={classes.modalNameHighlight}>
                              {t("Language") + ":"}
                            </span>
                            <span className={classes.modalName}>
                              {userStore.user && userStore.user?.language?.name}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
                <li
                  className={clsx(
                    classes["header__list-item"],
                    classes["header__user"],
                    disableHeader && !allowLogout && classes.disabled,
                  )}
                >
                  <NavLink
                    to={path}
                    className={clsx(
                      classes["header__list-link"],
                      isActiveLink && classes.active,
                      (!isSuccessLocalPreferences && !allowLogout) ||
                        (!userStore.user.email_confirmed && classes.disabled),
                    )}
                  >
                    <User name={userName} />
                  </NavLink>
                  <div className={classes.modal}>
                    <ul className={classes.modalList}>
                      {!disabled && (
                        <li className={classes.modalItem}>
                          <NavLink
                            to={path}
                            className={navData =>
                              clsx(
                                classes.modalLink,
                                navData.isActive && classes.modalLinkActive,
                              )
                            }
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <ShapeGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t("Profile")}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      )}
                      {!disabled && (isAdmin || isManager) && (
                        <li className={classes.modalItem}>
                          <NavLink
                            to={`${process.env.REACT_APP_API}/globaladmin/`}
                            className={classes.modalLink}
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <AdminGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t("Admin panel")}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      )}
                      {!isTestUser && <li className={classes.modalItem}>
                        <Button
                          onClick={resetCookies}
                          className={classes.modalButton}
                        >
                          <div className={classes.modalInner}>
                            <div className={classes.modalIcon}>
                              <LogoutGradientIcon />
                            </div>
                            <span className={classes.modalName}>
                              {t("Log out")}
                            </span>
                          </div>
                        </Button>
                      </li>}
                      {RUSSIAN_LOCALE && !isTestUser && (
                        <li className={classes.modalItem}>
                          <Button
                            onClick={goToKokocID}
                            className={classes.modalButton}
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <LogoutGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t("Passport KokocID")}
                              </span>
                            </div>
                          </Button>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            {width <= 1024 && active && (
              <ul className={classes["header__admin-list"]}>
                {!disabled && (isAdmin || isManager) && (
                  <li className={classes["header__admin-list-item"]}>
                    <NavLink
                      to={`${process.env.REACT_APP_API}/globaladmin/`}
                      className={classes.modalLink}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <AdminGradientIconActive />
                        </div>
                        <span className={classes.modalName}>
                          {t("Admin panel")}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {!RUSSIAN_LOCALE && (
                  <li className={classes["header__admin-list-item"]}>
                    <Button
                      onClick={() => {
                        handleOpenLocalPreferencesModal();
                        setIsOpenLocalPreferencesModal(true);
                      }}
                      className={clsx(classes.modalButton)}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <GlobusGradientIcon />
                        </div>
                        <span className={classes.modalName}>
                          {t("Local preferences")}
                        </span>
                      </div>
                    </Button>
                  </li>
                )}

                {!isTestUser && <li className={classes["header__admin-list-item"]}>
                  <Button
                    onClick={resetCookies}
                    className={clsx(classes.modalButton)}
                  >
                    <div className={classes.modalInner}>
                      <div className={classes.modalIcon}>
                        <LogoutGradientIcon />
                      </div>
                      <span className={classes.modalName}>{t("Log out")}</span>
                    </div>
                  </Button>
                </li>}
              </ul>
            )}
            {active && (
              <footer className={clsx(classes["burger-footer"])}>
                <div className={classes["burger-footer__inner"]}>
                  <div className={classes["burger-footer__copy"]}>
                    &copy; 2024 {t("KIT Global")}
                  </div>
                  <LinksPolitic />
                </div>
              </footer>
            )}
          </nav>
          <Modal
            isOpen={isOpenLocalPreferencesModal}
            title={t("Local preferences")}
            onClose={handleCloseLocalPreferencesModal}
          >
            <LocalPreferencesForm
              onClose={handleCloseLocalPreferencesModal}
              onSave={handleSendLocalPreferences}
              success={isSuccessLocalPreferences}
              disabled={isDisabledLocalPreferences}
              noticeText={t(
                "Local settings affect the interface language and prices you see on the platform",
              )}
              countries={countries}
              currencies={currencies}
              languages={languages}
              currentCountry={userCountry}
              currentCurrency={userCurrency}
              currentLanguage={languages.find(
                item => item.lang_code === i18n.language,
              )}
            ></LocalPreferencesForm>
          </Modal>
        </header>
        {headerButtons}
      </>
    );
  },
);
