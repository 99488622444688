import { FC, useEffect, useMemo, useState, useCallback } from "react";
import { Filters } from "components/Filters";
import { ProductList } from "./ProductList";
import { useWindowWidth } from "hooks/useWindowWidth";
import clsx from "clsx";
import { Button } from "components/Button";
import { FilterIcon } from "components/Icons/FilterIcon";
import { Modal } from "components/Modal";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { observer } from "mobx-react-lite";
import { HotDealsCounter } from "./HotDeals/HotDealsCounter";
import classes from "./CardsContainer.module.scss";
import { MobileFilters } from "components/MobileFilters";
import { RecommendedService } from "containers/RecommendedService";
import { NavLink } from "react-router-dom";
import { Type } from "components/Type";
import { useTranslation } from "react-i18next";
import { useUserStore } from "contexts/UserContext";
import { marketPlaceStore } from "../../stores/marketPlaceStore";
import { Steps } from "intro.js-react";
import {
  solutionsMessagesWithoutPaginationAndWithoutServices,
  solutionsMessagesWithoutPaginationWithServices,
  solutionsMessagesWithPaginationWithoutServices,
  solutionsMessagesWithPaginationWithServices,
} from "./hints";
import "./IntroTooltip.css";
import "intro.js/introjs.css";
import {
  isOnboardingActive,
  setOnboardingOptions,
} from "../../tools/onboarding";
import productListClasses from "./ProductList/ProductList.module.scss";
import { LABELS } from "../../constants/onboarding";
import UiIcon from "../../components/Icon";
import { defaultSortParams, options, optionsRu } from "./utils";
import { RUSSIAN_LOCALE } from "utils";
import FiltersSkeleton from "components/Skeleton/FiltersSkeleton/FiltersSkeleton";

interface Props {
  title: string;
}

export const CardsContainer: FC<Props> = observer(({ title }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const userStore = useUserStore();
  const isLoadingSolutions = marketPlaceStore.isLoadingServicePages;
  const isLoadingService = marketPlaceStore.isLoadingServices;

  const allProducts = marketPlaceStore.services;
  const mobileScreen = useWindowWidth().isSmallLaptop;
  const enableHints =
    isOnboardingActive(userStore.pagesToOnboard?.solutions) &&
    allProducts?.length;
  const { currency, language, country } = userStore.user || {};

  const { t } = useTranslation();

  const products =
    window.location.pathname === "/solutions"
      ? marketPlaceStore.services
      : marketPlaceStore.favoriteServices;

  const pageName =
    window.location.pathname === "/solutions" ? "Solutions" : "Favorites";

  const isFavoritesPage = title === t("Favorites");

  const { count, maxCost, allCount, favoriteCount, sortingParams } =
    marketPlaceStore || {};
  const chunkSize = 8;

  const [isFree, setIsFree] = useState(
    (!isFavoritesPage && sortingParams?.isFree) || false,
  );
  const [isNew, setIsNew] = useState(
    (!isFavoritesPage && sortingParams?.isNew) || false,
  );
  const [isHot, setIsHot] = useState(
    (!isFavoritesPage && sortingParams?.isHot) || false,
  );
  const [isSale, setIsSale] = useState(
    (!isFavoritesPage && sortingParams?.isSale) || false,
  );
  const [isTopUp, setIsTopUp] = useState(
    (!isFavoritesPage && sortingParams?.isTopUp) || false,
  );

  const [productsToShow, setProductsToShow] = useState<any>(products);
  const [serviceTypes, setServiceTypes] = useState<any[]>(
    (!isFavoritesPage && sortingParams?.savedServiceTypes) || [],
  );
  const [businessTypes, setBusinessTypes] = useState<any[]>(
    (!isFavoritesPage && sortingParams?.savedBusinessTypes) || [],
  );
  const [serviceTypesOfMarketPlace, setServiceTypesOfMarketPlace] =
    useState<any>(serviceTypes);
  const [businessTypesOfMarketplace, setBusinessTypesOfMarketPlace] =
    useState<any>(businessTypes);
  const [budget, setBudget] = useState(
    (!isFavoritesPage && sortingParams?.savedBudget) || 0,
  );
  const [priceMin, setPriceMin] = useState(
    !isFavoritesPage ? sortingParams?.savedPriceMin : 0,
  );
  const [showFilters, setShowFilters] = useState(false);
  const { isMediaTablet } = useWindowWidth();
  const [recommendedServicesIds, setRecommendedServicesIds] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [sortSelect, setSortSelect] = useState<any>(
    (!isFavoritesPage &&
      sortingParams.sortSelect?.id &&
      sortingParams.sortSelect) ||
      options(t)[0],
  );
  const [clearBusinessTypes, setClearBusinessTypes] = useState(false);

  const [openSelect, setOpenSelect] = useState(false);

  const [moreButtonClicked, setMoreButtonClicked] = useState(false);

  const hasRecommendedServices = products.some(
    ({ recommended_service }: any) => recommended_service?.length,
  );

  const allActiveProducts = useMemo(
    () =>
      allProducts
        ?.filter(({ is_active, is_favorite }: any) => is_active && !is_favorite)
        .slice(0, 7),
    [],
  );

  useEffect(() => {
    (RUSSIAN_LOCALE ? optionsRu(t) : options(t))?.forEach((option: any) => {
      if (option?.id === sortSelect?.id) {
        setSortSelect(option);
      }
    });
  }, [sortSelect.name, userStore.user.language.id]);

  const topFilters = {
    defaultIsFree: isFree,
    defaultIsNew: isNew,
    defaultIsHot: isHot,
    defaultIsSale: isSale,
    defaultIsTopUp: isTopUp,
  };

  useEffect(() => {
    if (isFavoritesPage) {
      if (hasRecommendedServices) {
        setRecommendedServicesIds(
          products.flatMap(
            ({ recommended_service }: any) => recommended_service,
          ),
        );
      } else {
        const randomIds = allActiveProducts.map(({ id }: any) => id);
        setRecommendedServicesIds(randomIds);
      }
    }
  }, [allActiveProducts, hasRecommendedServices, isFavoritesPage]);

  const sortServices = (servicesList: any) => {
    return servicesList.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    // setServiceTypesOfMarketPlace(sortServices(marketPlaceStore.serviceTypes));
    setServiceTypesOfMarketPlace(marketPlaceStore.serviceTypes);
    // If the user has a business type specified, then we display it first in the filter list.
    if (userStore.user.company_industry && !isFavoritesPage) {
      const indexUserCompanyIndustry = marketPlaceStore.businessTypes.findIndex(
        (item: any) => item.id === userStore.user.company_industry,
      );
      if (indexUserCompanyIndustry > -1) {
        setBusinessTypesOfMarketPlace(marketPlaceStore.businessTypes);
      }
    } else {
      // setBusinessTypesOfMarketPlace(sortServices(marketPlaceStore.businessTypes));
      setBusinessTypesOfMarketPlace(marketPlaceStore.businessTypes);
    }
  }, [
    country,
    currency?.id,
    language?.id,
    marketPlaceStore?.serviceTypes,
    marketPlaceStore?.businessTypes,
  ]);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ solutions: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  const pagination = document.querySelector(
    `.${productListClasses.pagination}`,
  );

  useEffect(() => {
    if (pagination || products?.length <= 9) {
      setIsMobile(mobileScreen && window.location.pathname !== "/favorites");
    }
  }, [pagination, mobileScreen]);

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const sortParams = defaultSortParams(sortSelect);

  const savedPageParams = {
    savedCurrentPage: currentPage,
    savedChunkSize: chunkSize,
    savedServiceTypes: serviceTypes,
    savedBusinessTypes: businessTypes,
    isFavoritesPage,
    savedBudget: budget,
    savedPriceMin: priceMin,
    savedSortParams: sortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    sortSelect,
    clearBusinessTypes: clearBusinessTypes,
  };

  useEffect(() => {
    if (isFavoritesPage) return;
    if (marketPlaceStore.isLoadingServices) return;

    marketPlaceStore.setSortingParams(savedPageParams);
  }, [
    currentPage,
    chunkSize,
    serviceTypes,
    businessTypes,
    isFavoritesPage,
    budget,
    sortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    priceMin,
  ]);

  const isServicesLength =
    serviceTypesOfMarketPlace.length > 0 &&
    businessTypesOfMarketplace.length > 0;

  // Below is the code to define the onboarding steps
  const { is_free, is_new, is_hot, is_sale, is_topup } =
    marketPlaceStore.isTopServices;

  const {
    is_free_favorite,
    is_hot_favorite,
    is_new_favorite,
    is_sale_favorite,
    is_topup_favorite,
  } = marketPlaceStore?.isFavoriteServices;

  const isTopServices = is_free || is_new || is_hot || is_sale || is_topup;
  const isFavoriteServices =
    is_free_favorite ||
    is_new_favorite ||
    is_hot_favorite ||
    is_sale_favorite ||
    is_topup_favorite;

  const steps = isFavoritesPage
    ? marketPlaceStore.allCount > 8
      ? isFavoriteServices
        ? solutionsMessagesWithPaginationWithServices(t)
        : solutionsMessagesWithPaginationWithoutServices(t)
      : isFavoriteServices
      ? solutionsMessagesWithoutPaginationWithServices(t) // When there is no pagination but has favorite services
      : solutionsMessagesWithoutPaginationAndWithoutServices(t) // When there is no pagination and no favorite services
    : marketPlaceStore.allCount > 8
    ? isTopServices
      ? solutionsMessagesWithPaginationWithServices(t)
      : solutionsMessagesWithPaginationWithoutServices(t)
    : isTopServices
    ? solutionsMessagesWithoutPaginationWithServices(t) // When there is no pagination but has top services
    : solutionsMessagesWithoutPaginationAndWithoutServices(t); // When there is no pagination and no top services

  //________________________________

  return (
    <>
      <TitleAndDescription pageName={pageName} />

      <Steps
        enabled={isMobile && (enableHints as boolean)}
        steps={steps}
        options={{
          tooltipClass: "customTooltip",
          highlightClass: "introjs-helperLayer",
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={() => {
          handleExit();
        }}
        onExit={handleExit}
      />

      <div className={clsx(classes.wrapper)}>
        {isFavoritesPage && !favoriteCount ? (
          <div className={classes["zero-favorites"]}>
            <div className={classes["zero-favorites__inner"]}>
              <div className={classes["zero-favorites__title"]}>
                <Type
                  tag="h2"
                  size="lg"
                  className={classes["zero-favorites__text"]}
                >
                  {t("No favorite solutions yet?")}
                </Type>
              </div>
              <div className={classes["zero-favorites__subtitle"]}>
                <Type
                  tag="h3"
                  size="simple"
                  className={classes["zero-favorites__text"]}
                >
                  {t("Try finding something interesting in our catalog")}
                </Type>
              </div>
              <div className={classes["zero-favorites__button"]}>
                <Button size="middle" theme="primary">
                  <NavLink
                    to="/solutions"
                    className={classes["zero-favorites__button"]}
                  >
                    {t("Back to Solutions")}
                  </NavLink>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.head}>
              <div
                className={classes.title}
                key={"Some_kind_of_key_so_that_it_doesn’t_break_Google"}
              >
                {t(`${title}`)}
                {/* <span>{isFavoritesPage ? favoriteCount : allCount}</span> */}
                <span>{allCount}</span>
              </div>

              {isMediaTablet && (
                <div className={classes.filters}>
                  <Button
                    onClick={() => setOpenSelect(true)}
                    theme="icon-default"
                  >
                    <UiIcon
                      name="FilterMobileTableIcon"
                      additionalClassName={classes.mobileSortIcon}
                    />
                  </Button>
                  <Button
                    onClick={() => setShowFilters(true)}
                    theme="icon-default"
                  >
                    <FilterIcon />
                  </Button>
                </div>
              )}
            </div>

            {isMediaTablet &&
              // (isServicesLength ? (
                <Modal
                  isOpen={showFilters}
                  onClose={() => setShowFilters(false)}
                  title={t("Filters")}
                  filterMobile
                  classNameForContent={classes.modalMobileFilters}
                >
                  <MobileFilters
                    count={count}
                    serviceTypesChecks={serviceTypes}
                    setServiceTypes={setServiceTypes}
                    serviceTypesOf={serviceTypesOfMarketPlace}
                    businessTypesOf={businessTypesOfMarketplace}
                    businessTypesChecks={businessTypes}
                    setBusinessTypes={setBusinessTypes}
                    budget={budget}
                    priceMin={priceMin}
                    setPriceMin={setPriceMin}
                    maxCost={maxCost}
                    currency={userStore.user.currency}
                    setBudget={setBudget}
                    isOpen={showFilters}
                    onClose={() => setShowFilters(false)}
                    setCurrentPage={setCurrentPage}
                    setProductsToShow={setProductsToShow}
                    pageSize={pageSize}
                    topFilters={topFilters}
                    isFavorites={isFavoritesPage}
                    sortSelect={sortSelect}
                    setMoreButtonClicked={setMoreButtonClicked}
                    clearBusinessTypes={clearBusinessTypes}
                    setClearBusinessTypes={setClearBusinessTypes}
                    setIsFirstRender={setIsFirstRender}
                  />
                </Modal>
              // ) : (
              //   <FiltersSkeleton />
              // ))
              }

            <div className={classes.content}>
              <div className={classes.left}>
                {!isMediaTablet &&
                  // (isServicesLength ? (
                    <Filters
                      setIsFirstRender={setIsFirstRender}
                      isLoading={isLoadingService}
                      serviceTypesChecks={serviceTypes}
                      setServiceTypes={setServiceTypes}
                      businessTypesChecks={businessTypes}
                      setBusinessTypes={setBusinessTypes}
                      serviceTypesOf={serviceTypesOfMarketPlace}
                      businessTypesOf={businessTypesOfMarketplace}
                      budget={budget}
                      priceMin={priceMin}
                      setPriceMin={setPriceMin}
                      maxCost={maxCost}
                      currency={userStore.user.currency}
                      setBudget={setBudget}
                      setCurrentPage={setCurrentPage}
                      setProductsToShow={setProductsToShow}
                      pageSize={pageSize}
                      topFilters={topFilters}
                      isFavorites={isFavoritesPage}
                      sortSelect={sortSelect}
                      setMoreButtonClicked={setMoreButtonClicked}
                      clearBusinessTypes={clearBusinessTypes}
                      setClearBusinessTypes={setClearBusinessTypes}
                    />
                  // ) : (
                  //   <FiltersSkeleton />
                  // ))
                  }
              </div>

              <div className={classes.right}>
                <ProductList
                  isFirstRender={isFirstRender}
                  isLoading={isLoadingSolutions}
                  isServicesLength={isServicesLength}
                  setIsFree={setIsFree}
                  setIsNew={setIsNew}
                  setIsHot={setIsHot}
                  setIsSale={setIsSale}
                  setIsTopUp={setIsTopUp}
                  data={productsToShow}
                  hotDeals={marketPlaceStore.dataTimeHotDeals}
                  nameSource={title}
                  defaultCurrentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  defaultServiceTypes={serviceTypes}
                  defaultBusinessTypes={businessTypes}
                  topFilters={topFilters}
                  isFavorites={isFavoritesPage}
                  defaultBudget={budget}
                  defaultPriceMin={priceMin}
                  enableHints={enableHints as boolean}
                  sortSelect={sortSelect}
                  setSortSelect={setSortSelect}
                  openSelect={openSelect}
                  setOpenSelect={setOpenSelect}
                  defaultChunkSize={chunkSize}
                  setMoreButtonClicked={setMoreButtonClicked}
                  moreButtonClicked={moreButtonClicked}
                  setPriceMin={setPriceMin}
                  clearBusinessTypes={clearBusinessTypes}
                />
              </div>
            </div>
          </div>
        )}

        {window.location.pathname === "/favorites" && (
          <RecommendedService
            ids={recommendedServicesIds}
            nameSource="Favorites"
          />
        )}

        <HotDealsCounter />
      </div>
    </>
  );
});
