import axios from "axios"
import { makeAutoObservable } from "mobx"
import AuthService from "services/auth"
import { API_URL, getRefreshToken, setAccessToken, setRefreshToken } from "services/http"
import {gaClientId} from "../tools/analytics";




class AuthStore {
    isAuth = false
    isInit = false
    isLoading = false
    user = {}
    accessToken = ''
    kid_user_info: {} | null = null

    constructor() {
        makeAutoObservable(this)
    }

    setUser(user: any) {
        this.user = user
    }

    setAuth(bool: boolean) {
        this.isAuth = bool
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    setInit(bool: boolean) {
        this.isInit = bool
    }

    setKidUserInfo(user: any) {
        this.kid_user_info = user
    }

    async loginByTokens(access: string, refresh: string) {
        this.setLoading(true)
        try {
          setAccessToken(access)
          setRefreshToken(refresh)
          return true
        }
        catch {
          return false
        }
        finally {
          this.setLoading(false);
        }
    }

    async login(email: string, password: string, recaptcha: string, gaClientId: string) {
        this.setLoading(true)
        try {
            const response = await AuthService.login(email, password, recaptcha, gaClientId)
            if (response.data.status) {
                this.setAuth(true)
                setAccessToken(response.data.data.access)
                setRefreshToken(response.data.data.refresh)
            }
            this.setLoading(false)
            return response.data
        } catch (e: any) {
            return e.response.data
        } finally {
            this.setLoading(false)
        }
    }

    async checkToken(token: string) {
        this.setLoading(true)
        try {
            console.log(`${API_URL}/api/v1/auth/kid/check-token/`)
            const response = await axios.get(`${API_URL}/api/v1/auth/kid/check-token/`, {params: {token}})
            if (response.data.status) {
                if (!!response.data.data.access) {
                    console.log('token is ok, user is present:', response.data.data)
                    this.setAuth(true)
                    setAccessToken(response.data.data.access)
                    setRefreshToken(response.data.data.refresh)
                } else {
                    console.log('token is ok, user:', response.data.data)
                    this.setKidUserInfo({...response.data.data, token})
                }
            } else {
                console.log('token status is false:', response.data)
            }
            this.setLoading(false)
            return response.data.status ? true : response.data.form_errors.token;
        } catch (e: any) {
            this.setKidUserInfo(null)
            console.log('check_token catch', e.response)
        } finally {
            this.setLoading(false)
        }
    }

    async logout() {
        try {
            // await AuthService.logout()
            // console.log('hoho', process.env.REACT_APP_KID_URL);
            // if (process.env.REACT_APP_KID_URL) {
            //     const response = await axios.get(
            //         process.env.REACT_APP_KID_URL,
            //         {
            //             params: {
            //                 site_id: process.env.REACT_APP_KID_SITE_ID,
            //                 logout: true
            //             }
            //         }
            //     )
            // }

            setAccessToken('')
            setRefreshToken('')
            this.setAuth(false)
        } catch (e: any) {
            console.log(e.response)
        }
    }

    async refreshAuth() {
        if (!getRefreshToken()) return
        this.setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/refresh/`, {refresh: getRefreshToken()})
            if (response.data.access) {
                setAccessToken(response.data.access)
                this.setAuth(true)
                this.accessToken = response.data.access
                return true
            }
            return false
        } catch (e: any) {
            return false
        } finally {
            this.setLoading(false)
            this.setInit(true)
        }
        return
    }

    async verifyAuth() {
        this.setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/verify/`, {token: getRefreshToken()})
            this.setAuth(true)
            return true
        } catch (e: any) {
            this.setAuth(false)
            return false
        } finally {
            this.setLoading(false)
            this.setInit(true)
        }
    }
}

export const authStore = new AuthStore()
