import React, { FC } from 'react';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import clsx from 'clsx';
import FormatPrice from 'components/FormatPriceAndCurrency';
import classes from '../../CartCard.module.scss'; 
import { useTranslation } from 'react-i18next';

interface Currency {
  symbol: string;
}

interface PriceInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  isTablet: boolean;
  itemCount: number;
  cardCostWithText: string;
  hasDiscount: boolean;
  currency: Currency | undefined;
  modalContent: () => string;
}

export const PriceInfoModal: FC<PriceInfoModalProps> = ({
  isOpen,
  onClose,
  isTablet,
  itemCount,
  cardCostWithText,
  hasDiscount,
  currency,
  modalContent,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={''}
      isPriceModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div
        className={clsx(classes.modalPriceContainer, classes.modalContainer)}
      >
        <div className={classes.modalInner}>
          {isTablet && itemCount > 1 && cardCostWithText && hasDiscount ? (
            <p className={clsx(classes.modalText)}>
              <FormatPrice currency={currency?.symbol || ''} />
              {cardCostWithText}
            </p>
          ) : (
            <p
              dangerouslySetInnerHTML={{ __html: modalContent() }}
              className={classes.modalText}
            />
          )}
        </div>
        <div className={classes.buttonModalWrap}>
          <Button
            theme='primary'
            onClick={onClose}
            className={classes.buttonModal}
          >
            {t("I understood")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
