import { userStore } from "stores/userStore";
import classes from "./Finances.module.scss";
import { formatBalanceForLocale } from "./formatBalanceForLocale";
import { FinancesTransaction } from "types/finances";
import { getFormattedDate, getFormattedTime } from "tools/formattedDate";
import { toJS } from "mobx";

export const columnsArrayTransactions = (t: any) => [
  {
    header: "",
    accessor: "serialNumber",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: "Transaction",
    accessor: "transactions",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: "Targets",
    accessor: "objects",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: "Status",
    accessor: "status",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: "Data",
    accessor: "data",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: "Sum",
    accessor: "amount",
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
];

export const tableDataTransactions = (
  transactions: FinancesTransaction[],
  t: any,
): any => {
  const currency = userStore.user.currency?.char_code;
  const currentCurrencySymbol = userStore.user.currency?.symbol;

  console.log("transactions", toJS(transactions));

  return transactions?.map((transaction, index) => {
    const transactionDate = new Date(transaction?.created_at);

    return {
      serialNumber: {
        value: index + 1,
        className: classes.table__header,
      },
      transactions: {
        value: transaction.money_transfer_description.label,
        className: classes.table__header,
        description: {
          text: t(transaction.type.label),
          className: classes.table__text,
        },
      },
      objects: transaction.project
        ? {
            value: transaction.project.name,
            className: classes.table__header,
            link: `/projects/${transaction.project.id}`,
            linkOptions: window.location.href.indexOf("finances") !== -1,
            description: {
              text: `ID ${transaction.project.id}`,
              className: classes.table__text,
            },
          }
        : transaction.project
        ? {
            value: transaction.service.name,
            className: classes.table__header,
          }
        : {},
      status: {
        label: transaction.money_transfer_status.label,
        type: transaction.money_transfer_status.value,
        className: classes.table__header,
      },
      data: {
        value: getFormattedDate(transactionDate),
        className: classes.table__header,
        description_date: {
          text: getFormattedTime(transactionDate),
          className: classes.table__text,
        },
      },
      amount: {
        value: formatBalanceForLocale(
          Number(transaction.amount),
          transaction.currency?.char_code
            ? transaction.currency.char_code
            : currency,
          currentCurrencySymbol,
        )?.replace(/^-/, ""),
        className: classes.table__header,
      },
    };
  });
};
