import { useState } from "react";
import { usePagination, useTable, useSortBy } from "react-table";
import classes from "./TableTransactions.module.scss";
import clsx from "classnames";
import { DownloadIcon } from "../Icons/DownloadIcon";
import { NavLink } from "react-router-dom";
import { Fader } from "../Fader";
import FilterTableIcon from "../Icons/FilterTableIcon";
import { Pagination } from "../Pagination";
import _ from "lodash";
import { handleDownload } from "../../tools/fileDownload";
import { TableCellProps, TableProps } from "./TableTransactions.props";
import { useTranslation } from "react-i18next";
import FilterSearch from "./FilterSearch/FilterSearch";
import FilterAmount from "./FilterAmount/FilterAmount";
import FilterData from "./FilterData/FilterData";
import { ModalMobile } from "components/ModalMobile";
import { MobileFilter } from "./MobileFilter";

const TableTransactions = ({
  data,
  columns,
  tableActive,
  additionalClassNames,
  id,
  isMobile = true,
  setOrdering,
  ordering,
  customPageCount,
  currentPage,
  setCurrentPage,
}: TableProps) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    previousPage,
    prepareRow,
    setPageSize,
    // pageCount,
    page,
    nextPage,
    gotoPage,
    state: { pageIndex },
  } = useTable({ columns, data }, useSortBy, usePagination);
  const [sortedData, setSortedData] = useState<any>(page);

  const [showTopUpModal, setShowTopUpModal] = useState(false);

  const handleShowTopUpModal = () => {
    setShowTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
  };

  const renderFilterByHeader = (header: string) => {
    switch (header) {
      // DO NOT DELETE, for now we just decided not to do such filtering, ready-made components
      // case "Transactions":
      //   return (
      //     <FilterSearch
      //       classNameForButton={classes.table__search}
      //       buttonElement={<FilterTableIcon />}
      //     >
      //       <FilterSelects
      //         filter={transactionFilter}
      //         setFilter={setTransactionFilter}
      //         filtersItem={mockFilterTransaction}
      //       />
      //     </FilterSearch>
      //   );
      // case "Projects":
      //   return (
      //     <FilterSearch
      //       classNameForButton={classes.table__search}
      //       buttonElement={<FilterTableIcon />}
      //     >
      //       <FilterSelects
      //         filter={projectsFilter}
      //         setFilter={setProjectsFilter}
      //         filtersItem={mockFilterTransaction}
      //       />
      //     </FilterSearch>
      //   );
      // case "Status":
      //   return (
      //     <FilterSearch
      //       classNameForButton={classes.table__search}
      //       buttonElement={<FilterTableIcon />}
      //     >
      //       <FilterSelects
      //         filter={statusFilter}
      //         setFilter={setStatusFilter}
      //         filtersItem={mockFilterTransaction}
      //       />
      //     </FilterSearch>
      //   );
      case "Data":
        return (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<FilterTableIcon />}
          >
            <FilterData setOrdering={setOrdering} />
          </FilterSearch>
        );
      case "Amount":
        return (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<FilterTableIcon />}
            isLastFilter={true}
          >
            <FilterAmount setOrdering={setOrdering} />
          </FilterSearch>
        );
      default:
        return null;
    }
  };

  const mainContent = (value: TableCellProps) => {
    if (value?.filePath) {
      return (
        <span
          className={clsx(
            classes.table__link,
            value?.className?.length && String(value?.className),
          )}
          onClick={() =>
            value?.filePath && handleDownload(value?.filePath, true)
          }
        >
          {value?.value}
          {value?.filePath && (
            <DownloadIcon
              onClick={() => handleDownload(value?.filePath as string, true)}
            />
          )}
        </span>
      );
    } else if (value?.link && value?.linkOptions) {
      return (
        <NavLink
          to={process.env.REACT_APP_PUBLIC_URL + value?.link}
          className={clsx(
            classes.table__firstlink,
            classes.table__link,
            value?.className?.length && String(value?.className),
          )}
        >
          {value?.value}
        </NavLink>
      );
    } else {
      return value?.value;
    }
  };

  return (
    <>
      <Fader active={tableActive as boolean}>
        <div
          className={clsx(
            classes.table,
            additionalClassNames && String(additionalClassNames),
          )}
          id={id}
        >
          {isMobile && (
            <div className={classes.table__row_mob}>
              <button
                className={classes.table__list_mob}
                onClick={handleShowTopUpModal}
              >
                <FilterTableIcon />
              </button>

              <ModalMobile
                title=""
                isOpen={showTopUpModal}
                onClose={handleCloseTopUpModal}
                isBasicHeader={false}
              >
                <MobileFilter ordering={ordering} setOrdering={setOrdering} />
              </ModalMobile>
            </div>
          )}

          <table {...getTableProps()} className={classes.table__wrapper}>
            <thead>
              {headerGroups?.map(headerGroup => {
                console.log("headerGroup", headerGroup);

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className={classes.table__row}
                  >
                    <th className={classes.table__cell}>
                      {/* DO NOT DELETE, for now we just decided not to do such filtering, ready-made components */}
                      {/* <FilterSearch
                        classNameForButton={classes.table__search}
                        buttonElement={<SearchIcon />}
                      >
                        <p className={classes.search__description}>
                          Search by table
                        </p>
                        <Input
                          className={classes.search__input}
                          onChange={setSearch}
                          type={"text"}
                          placeholder={t("Enter value...")}
                          value={search}
                          inputForCurrency={true}
                        />
                      </FilterSearch> */}
                    </th>

                    {headerGroup?.headers?.map((column: any) => {
                      if (column?.header)
                        return (
                          <th
                            className={classes.table__cell}
                            {...column.getHeaderProps()}
                          >
                            {t(column.render("header"))}

                            {renderFilterByHeader(column.header)}
                          </th>
                        );

                      return null;
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {sortedData?.map((row: any) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} className={classes.table__content}>
                    {row?.cells?.map((cell: any) => {
                      const { value } = cell;

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.table__cell}
                        >
                          <div className={classes.table__cell__container}>
                            <div className={classes.table__header}>
                              {mainContent(value)}
                            </div>

                            <div className={value?.description?.className}>
                              {value?.description?.text}
                            </div>

                            <div className={classes.description_date}>
                              {value?.description_date?.text}
                            </div>

                            {value?.label && (
                              <div
                                className={clsx(
                                  classes.label,
                                  classes[value.label],
                                  classes[`type_${value.type}`],
                                )}
                              >
                                {t(value?.label)}
                              </div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fader>

      {customPageCount > 1 && (
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalPages={customPageCount}
            setPageNumber={setCurrentPage}
            nextPage={nextPage}
            prevPage={previousPage}
          />
        </div>
      )}
    </>
  );
};

export default TableTransactions;
