import React, { FC, useCallback, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import clsx from "clsx";
import { Link } from "../../components/Link";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";

import logo from "img/logo.png";
import logo_ru from "img/logo_ru.svg";
import desktop from "img/Restore/desktop.png";
import lock_ru from "img/Restore/lock_ru.png";

import { useWindowWidth } from "hooks/useWindowWidth";

import classes from "./Restore.module.scss";
import { Request } from "tools/request";
import { LeftArrowIcon } from "components/Icons/LeftArrowIcon";
import { SuccessIcon } from "components/Icons/SuccessIcon";
import { toNbsp } from "tools/toNbsp";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MultiselectComponent } from "../../components/MultiselectComponent";
import { languages } from "../../constants/languages";
import { RUSSIAN_LOCALE } from "utils";

interface Props {}

interface FormValues {
  email: string;
  recaptcha: string;
}

export const RestoreContainer: FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const restoreValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Invalid Email"))
      .required(t("This field is required")),
    recaptcha: yup.string().required(t("This field is required")),
  });
  const {
    getValues,
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(restoreValidationSchema),
    mode: "all",
  });
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMediaTablet } = useWindowWidth();

  const { email, recaptcha } = getValues();

  const submit = useCallback(
    async (e: any) => {
      let formData = new FormData();

      formData.append("email", email);
      formData.append("g-recaptcha-response", recaptcha);

      await Request({
        type: "POST",
        url: `${process.env.REACT_APP_API}/api/v1/auth/restore/`,
        data: formData,
      })
        .then(() => {
          setStep(1);
        })
        .catch(error => {
          if (error?.response?.data) {
            setError("email", {
              type: "server",
              message: error.response.data?.email[0],
            });
          }
        });
    },
    [email, recaptcha, setError],
  );

  const changeLanguage = (langObj: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has("lng")) {
      newSearchParams.delete("lng");
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem("kitGlobalLng", langObj.lang_code);
  };

  return (
    <>
      <TitleAndDescription pageName="Restore" />
      <div className={classes.container}>
        <NavLink
          to={RUSSIAN_LOCALE ? "#" : `${process.env.REACT_APP_MAIN_SITE_URL}`}
        >
          <img
            src={RUSSIAN_LOCALE ? logo_ru : logo}
            alt=""
            className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)}
          />
        </NavLink>
        <div className={classes.left}>
          <img
            src={RUSSIAN_LOCALE ? lock_ru : desktop}
            alt=""
            className={classes.image}
          />
        </div>
        <div className={classes.right}>
          {!RUSSIAN_LOCALE && (
            <div className={classes.lang}>
              <MultiselectComponent
                data={languages}
                langSelect={true}
                defaultSelected={
                  languages[
                    languages.findIndex(
                      lang => lang.lang_code === i18n.language,
                    )
                  ].id
                }
                setMultiselect={changeLanguage}
                className={classes["multiselect-lang"]}
              />
            </div>
          )}
          {step === 0 && (
            <>
              <div className={classes.title}>{t("Forgot password")}?</div>
              <div className={classes.text}>
                {t("Enter your email to restore password")}
              </div>
              <form className={classes.form} onSubmit={handleSubmit(submit)}>
                <div className={classes.fields}>
                  <div className={classes["input-field"]}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Input
                          label={t("Email")}
                          labelPosition={"left"}
                          type={"text"}
                          placeholder={t("Enter your email")}
                          value={field.value}
                          error={!!errors?.email}
                          onChange={field.onChange}
                        />
                      )}
                      name="email"
                    />
                    {errors?.email && !isMediaTablet && (
                      <TooltipContainer
                        text={errors.email?.message}
                        customClasses={"kit-ui-block"}
                        position={"top"}
                        className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px" />
                      </TooltipContainer>
                    )}
                  </div>
                </div>
                {errors?.email && isMediaTablet && (
                  <div className={classes["mobile-error"]}>
                    {errors.email?.message}
                  </div>
                )}
                <div className={classes.captcha}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <ReCAPTCHA
                        sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                        onChange={field.onChange}
                        hl="en"
                        size="normal"
                      />
                    )}
                    name="recaptcha"
                  />
                </div>
                <div className={classes.buttons}>
                  <Link className={classes["return-button"]} href="/login">
                    <LeftArrowIcon />
                  </Link>
                  <Button
                    className={clsx(classes.button, classes["submit-button"])}
                    theme="primary"
                    disabled={!isValid}
                  >
                    {t("Restore")}
                  </Button>
                </div>
              </form>
            </>
          )}
          {step === 1 && (
            <>
              <div className={classes.heading}>
                <SuccessIcon />
                {/* {toNbsp(t('Restore password link sent'))} */}
              </div>
              <div className={classes.body}>
                {!isMediaTablet && (
                  <div className={classes.text}>
                    {t(
                      "We’ve sent you a link for restoring password. Please check your email",
                    )}
                    {/* {t('your email ')}  */} {email}
                  </div>
                )}
                {isMediaTablet && (
                  <div className={classes.text}>
                    {t(
                      "We’ve sent you a link for restoring password. Please check your email",
                    )}{" "}
                    {email}
                  </div>
                )}
                {!isMediaTablet && (
                  <div className={classes.help}>
                    {t("Can’t find it? Check your spam folder or")}
                    <span className={classes.link}>{t("resend a link")}</span>.
                  </div>
                )}
                {isMediaTablet && (
                  <div className={classes.help}>
                    <div>{t("Can’t find it?")}</div>
                    {t("Check your spam folder or")}
                    <span className={classes.link}>{t("resend a link")}</span>.
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
