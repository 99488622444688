import * as React from "react";
import { SVGProps } from "react";

export const LoginRuBGLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    // width="829"
    // height="496"
    viewBox="0 0 829 496"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M828.382 496.181H0V30.586C248.461 -37.0131 430.917 -7.80499 415.5 274.681C593.679 251.605 737.225 371.925 828.382 496.181Z"
      fill="white"
      fill-opacity="0.3"
    />
  </svg>
);
