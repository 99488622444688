import React, { FC } from "react";
import { RegistrationFormRu } from "./RegistrationFormRu";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { useAuthStore } from "contexts/AuthContext";
import classes from "./RegistrationRu.module.scss";
import { toJS } from "mobx";
import { LoginRuBGRightIcon } from "components/Icons/LoginRuBGRightIcon";
import { LoginRuBGLeftIcon } from "components/Icons/LoginRuBGLeftIcon";
import { NavLink } from "react-router-dom";
import logo_ru from "img/logo_ru.svg";

export const RegistrationRUContainer: FC = () => {
  const authStore = useAuthStore();

  const kid_user_info = toJS(authStore).kid_user_info;

  return (
    <>
      <TitleAndDescription pageName="Registration" />
      <div className={classes.container}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo_ru} alt="" className={classes.logo} />
        </NavLink>

        <RegistrationFormRu kidUserInfo={kid_user_info} />

        <div className={classes.right}>
          <LoginRuBGRightIcon />
        </div>

        <div className={classes.left}>
          <LoginRuBGLeftIcon />
        </div>
      </div>
    </>
  );
};
