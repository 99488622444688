import React, { FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import clsx from "clsx";
import {
  useNavigate,
  useLocation,
  NavLink,
  useSearchParams,
} from "react-router-dom";

import { Link } from "../../components/Link";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { EyeIcon } from "components/Icons/EyeIcon";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useCookies } from "react-cookie";

import logo from "img/logo.png";

import logo_ru from "img/logo_ru.svg";
import desktop from "img/Login/desktop.png";
import es_desktop from "img/Login/es_desktop.png";
import mobile from "img/Registration/mobile-solutions.png";
import es_mobile from "img/Registration/mobile-solutions-es.png";

import wordLogo from "img/Login/word-logo.png";
import wordLogoRu from "img/Login/word-logo-ru.svg";

import classes from './Login.module.scss';
import { useAuthStore } from 'contexts/AuthContext';
import { MultiselectComponent } from 'components/MultiselectComponent';
import { languages } from 'constants/languages';
import { t } from 'i18next';
import { RUSSIAN_LOCALE } from 'utils';
import {getGaClientId} from "../../tools/analytics";

interface Props {}

interface FormInputs {
  email: string;
  password: string;
}

export const LoginContainer: FC<Props> = observer(() => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [recaptcha, setRecaptcha] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [, setCookie] = useCookies(["access", "refresh"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isMediaTablet } = useWindowWidth();
  const authStore = useAuthStore();
  const location = useLocation();
  const { i18n, t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t("Enter a valid email address"))
      .required(t("The email address or password is incorrect")),
    password: yup.string().required(t("This field is required")),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormInputs>({ resolver: yupResolver(schema), mode: "onSubmit" });

  const changeLanguage = (langObj: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has("lng")) {
      newSearchParams.delete("lng");
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem("kitGlobalLng", langObj.lang_code);
  };

  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_RECAPCHA == "true") {
      if (email !== "" && password !== "") {
        setDisableSubmit(false);
        return;
      }
    } else {
      if (email !== "" && password !== "" && recaptcha !== "") {
        setDisableSubmit(false);
        return;
      }
    }
    setDisableSubmit(true);
  }, [email, password, recaptcha]);

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    const { email, password } = data;
    const getCookiesConsent = getGaClientId();

    try {
      const res = await authStore.login(email, password, recaptcha, getCookiesConsent);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      const { form_errors, status } = res || {};

      if (form_errors) {
        if (form_errors.email) {
          setError("email", { type: "server", message: form_errors.email });
        }
        if (form_errors.password) {
          setError("password", {
            type: "server",
            message: form_errors.password,
          });
        }
        if (form_errors.__all__) {
          setError("email", {
            type: "server",
            message: t(`The email address or password is incorrect`),
          });
        }
      } else if (status) {
        const origin =
          location.state?.from?.pathname ||
          process.env.REACT_APP_LOGIN_SUCCESS_URL;
        navigate(origin);
      }
    } catch (error: any) {
      setError("email", { type: "server", message: error.message });
    }
  };

  const loginWithKokocID = (event: Event) => {
    event.preventDefault();
    window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_SITE_ID}&3`;
  };

  const defaultLanguage =
    languages[languages.findIndex(lang => lang.lang_code === i18n.language)]
      ?.id;

  return (
    <>
      <TitleAndDescription pageName="Login" />
      <div className={classes.container}>
        <NavLink
          to={RUSSIAN_LOCALE ? "#" : `${process.env.REACT_APP_MAIN_SITE_URL}`}
        >
          <img
            src={RUSSIAN_LOCALE ? logo_ru : logo}
            alt=""
            className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)}
          />
        </NavLink>
        <div className={classes.left}>
          <div className={classes["left-title"]}>
            {t("KIT Global — world-class digital solutions")}
          </div>
          <div className={classes["left-text"]}>
            {t(
              "We know how to improve your business through internet marketing with no limits",
            )}
          </div>
          <img
            src={
              isMediaTablet
                ? i18n.language === "es"
                  ? es_mobile
                  : mobile
                : i18n.language === "es"
                ? es_desktop
                : desktop
            }
            alt=""
            className={classes.image}
          />
          {/* {isMediaTablet && <img src={mobileTwo} alt="" className={classes['image-two']} />} */}
        </div>
        <div className={classes.right}>
          {process.env.REACT_APP_PLATFORM_TYPE !== "ru" && (
            <div className={classes.lang}>
              <MultiselectComponent
                data={languages}
                langSelect={true}
                defaultSelected={defaultLanguage}
                setMultiselect={changeLanguage}
                className={classes.multiselect}
              />
            </div>
          )}
          <div className={classes.title}>
            {t("Login to")} <span>{t("KIT Global")}</span>
          </div>

          <div className={classes.text}>
            <span>
              {t("Don’t have an account")}?
              <Link className={classes.link} href="/register">
                {t("Sign up")}
              </Link>
            </span>
          </div>
          {errors.email && (
            <div className={classes["mobile-error"]}>
              {errors.email.message}
            </div>
          )}

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.fields}>
              <div className={classes["input-field"]}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }: { field: any }) => (
                    <Input
                      label={t("Email")}
                      labelPosition={"left"}
                      type={"text"}
                      placeholder={t("Enter your email")}
                      value={field.value}
                      onChange={e => {
                        field.onChange(e);
                        setEmail(e);
                      }}
                    />
                  )}
                />
                {/* {emailError && !isMediaTablet &&
                            <TooltipContainer
                              text={emailErrorText}
                              customClasses={'kit-ui-block'}
                              position={'top'}
                              className={classes.error}
                            >
                              <InfoIcon fill="#F5222D" width="18px" height="18px" />
                            </TooltipContainer>
                          } */}
              </div>
              <div className={classes["input-field"]}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }: { field: any }) => (
                    <Input
                      label={t("Password")}
                      labelPosition={"left"}
                      type={showPassword ? "text" : "password"}
                      placeholder={t("Enter password")}
                      value={field.value}
                      onChange={e => {
                        field.onChange(e);
                        setPassword(e);
                      }}
                    />
                  )}
                />
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  onKeyDown={() => {}}
                  className={classes["show-password"]}
                >
                  <EyeIcon width="18px" height="14px" />
                </div>
                {errors.password && !isMediaTablet && (
                  <TooltipContainer
                    text={errors.password.message}
                    customClasses={"kit-ui-block"}
                    position={"top"}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
                <Link
                  className={clsx(classes.link, classes.password)}
                  href="/restore"
                >
                  {t("I forgot my password")}
                </Link>
              </div>
            </div>
            {errors.password && isMediaTablet && (
              <div className={classes["mobile-error"]}>
                {errors.password?.message}
              </div>
            )}
            <div className={classes.captcha}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                onChange={e => e && setRecaptcha(e)}
                hl="en"
                size="normal"
              />
            </div>
            <Button
              className={clsx(classes.button, classes["submit-button"])}
              theme="primary"
              disabled={disableSubmit}
            >
              {t("Log in")}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
});
